import { convertDate, convertTimeStampToDate } from "./convertDate";

const fillMinuteData = (data, node, startTime, endTime) => {
  const sampleEmpty = {
    Freq: 0,
    Mean_Eact: 0,
    Mean_Eaparent: 0,
    Mean_Ereact: 0,
    Mean_PF: 0,
    Mean_Pact: 0,
    Mean_Paparent: 0,
    Mean_Preact: 0,
    PA_Irms: 0,
    PA_Vrms: 0,
    PB_Irms: 0,
    PB_Vrms: 0,
    PC_Irms: 0,
    PC_Vrms: 0,
    Phi_A: 0,
    Phi_B: 0,
    phi_C: 0,
  };

  const emptyData = data[0];

  emptyData[node] = sampleEmpty;

  startTime.setMinutes(startTime.getMinutes() + 1);
  endTime.setMinutes(endTime.getMinutes() - 1);

  let dat = [];

  while (startTime <= endTime) {
    let ts = convertDate(startTime);
    dat.push({ ...emptyData, Timestamp: parseInt(ts) });
    startTime.setMinutes(startTime.getMinutes() + 1);
  }

  return dat;
};

const ProcessData = (data, node, start, end) => {
  let startTime = convertTimeStampToDate(start);
  let endTime = convertTimeStampToDate(end);

  let correctedData = [];

  for (let i = 0; i < data.length; i++) {
    let timeCurr = convertTimeStampToDate(data[i].Timestamp.toString());

    let timeOne;
    let timeTwo;

    if (i === 0) {
      timeOne = startTime;
      timeTwo = timeCurr;
    } else if (i === data.length - 1) {
      timeOne = timeCurr;
      timeTwo = endTime;
    } else {
      timeOne = timeCurr;
      timeTwo = convertTimeStampToDate(data[i + 1].Timestamp.toString());
    }
    if (timeTwo.getTime() - timeOne.getTime() >= 600000) {
      let dat = fillMinuteData(data, node, timeOne, timeTwo);
      if (i === 0) {
        correctedData = [...dat];
        correctedData.push(data[i]);
      } else {
        correctedData.push(data[i]);
        correctedData = [...correctedData, ...dat];
      }
    } else {
      correctedData.push(data[i]);
    }
  }

  return correctedData;
};

export default ProcessData;
