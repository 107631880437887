import {
  TextField,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
  styled,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UserPool from "../UserPool";
import PasswordChecklist from "react-password-checklist";
import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";
import Notification from "./Notification";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import instance from "../UserPool";
import Timmer from "./Timmer";

const SignUpBtn = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  fontSize: "1rem",
  background:
    "linear-gradient(90deg, rgba(234,92,84,1) 0%, rgba(239,132,58,1) 42%, rgba(239,198,94,1) 100%)",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea5c54",endColorstr="#efc65e",GradientType=1)',
  marginTop: "5px",
}));

const Signup = (props) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [openNoti, setOpenNoti] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [orgId, setOrgId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [severity, setSeverity] = useState("error");
  const [otp, setOtp] = useState();
  const [sec, setSec] = useState(0);

  const [otpSent, setOtpSent] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const showSnackBar = () => {
    setOpenNoti(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const changeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    switch (name) {
      case "orgId":
        setOrgId(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPass":
        setConfirmPass(value);
        break;
      default:
        console.log("default");
        break;
    }
  };

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const verifyReg = (e) => {
    e.preventDefault();
    const userData = {
      Username: orgId.toUpperCase(),
      Pool: instance,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(otp, true, (err, res) => {
      if (err) {
        console.log(err);
        setErrorMessage("Wrong otp");
        setSeverity("error");
        showSnackBar();
        return;
      }
      setErrorMessage(
        "Account verified you can login using username and password"
      );
      setSeverity("success");
      showSnackBar();

      setInterval(() => {
        window.location.reload();
      }, 2000);
      console.log(res);
    });
  };

  const resendCode = () => {
    const userData = {
      Username: orgId.toUpperCase(),
      Pool: instance,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, res) => {
      if (err) {
        console.log(err);
        return;
      }
      setErrorMessage("Otp resent successfully");
      setSeverity("success");
      showSnackBar();
      setSec(60);
      console.log(res);
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (confirmPass !== password) {
      alert("Password is not matching");
      setLoading(false);
      return;
    }
    let attributeList = [];
    let dataEmail = {
      Name: "email",
      Value: email,
    };
    let dataUser = {
      Name: "name",
      Value: orgId.toUpperCase(),
    };

    attributeList.push(new CognitoUserAttribute(dataEmail));
    attributeList.push(new CognitoUserAttribute(dataUser));

    UserPool.signUp(
      orgId.toUpperCase(),
      password,
      attributeList,
      null,
      (err, data) => {
        if (err) {
          // if (err.message === "User already exists") {
          //   setOtpSent(true);
          //   resendCode();
          //   return;
          // }
          setErrorMessage(err.message);
          setSeverity("error");
          showSnackBar();
          setLoading(false);
          return;
        }
        console.log(data);
        setErrorMessage(`OTP sent to ${email}`);
        setSeverity("success");
        showSnackBar();
        setSec(60);
        setLoading(false);
        setOtpSent(true);
      }
    );
  };

  return (
    <div className="w-11/12 md:w-[30%] p-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg ">
      <div>
        <img
          className="mx-auto mb-3 p-3 h-16 "
          src={require("../asset/icon.png")}
          alt="zodhyaLogo"
        ></img>
      </div>
      {otpSent ? (
        <div>
          {" "}
          <form className="flex flex-col gap-3 " onSubmit={verifyReg}>
            <h1 className="text-[#545454] underline underline-offset-2">
              Enter otp
            </h1>
            <div className="">
              <MuiOtpInput
                length={6}
                value={otp}
                gap={1}
                TextFieldsProps={{
                  size: "small",
                  placeholder: "-",
                  sx: { width: "40px" },
                  required: true,
                }}
                onChange={handleOtpChange}
              />
            </div>
            <div className="flex gap-2 justify-between">
              <Button
                variant="contained"
                type="submit"
                size="small"
                // onClick={startTimer}
              >
                submit
              </Button>
              {sec !== 0 ? (
                <span className="text-sm text-[#545454]">
                  resend after <Timmer sec={sec} setSec={setSec}></Timmer> sec
                </span>
              ) : (
                <span
                  onClick={resendCode}
                  className="text-sm text-blue-500 underline cursor-pointer"
                >
                  resend
                </span>
              )}
            </div>
          </form>
        </div>
      ) : (
        <form onSubmit={submitHandler}>
          <div className="flex flex-col gap-2 justify-center items-center">
            <TextField
              id="userName"
              name="orgId"
              label="Enter Username"
              required={true}
              variant="filled"
              fullWidth
              autoFocus={true}
              onChange={changeHandler}
              value={orgId}
            ></TextField>
            <TextField
              id="email"
              name="email"
              label="Enter Email Address"
              type="email"
              required={true}
              variant="filled"
              fullWidth
              onChange={changeHandler}
              value={email}
            ></TextField>

            <TextField
              id="password"
              name="password"
              label="Enter Password"
              type="password"
              required={true}
              variant="filled"
              fullWidth
              value={password}
              onChange={changeHandler}
            ></TextField>
            <FormControl fullWidth variant="filled" required={true}>
              <InputLabel htmlFor="filled-adornment-password">
                Confirm Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                value={confirmPass}
                name="confirmPass"
                type={showPassword ? "text" : "password"}
                onChange={changeHandler}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {password !== "" && (
              <div className="w-[100%] ml-4">
                <PasswordChecklist
                  rules={[
                    "capital",
                    "match",
                    "specialChar",
                    "minLength",
                    "number",
                    "lowercase",
                  ]}
                  minLength={8}
                  value={password}
                  valueAgain={confirmPass}
                />
              </div>
            )}

            <SignUpBtn
              type="submit"
              variant="contained"
              className="w-11/12 "
              loading={loading}
            >
              Create account
            </SignUpBtn>

            <div className="text-left mt-3 text-[#545454]">
              Already have an account ?{" "}
              <span
                onClick={() => props.setIsLogin(true)}
                className="underline text-blue-600 cursor-pointer"
              >
                login here
              </span>
            </div>
          </div>
        </form>
      )}
      <hr className="h-px my-3 mb-3 bg-gray-200 border-0"></hr>
      <p className="text-gray-500 dark:text-gray-400 text-center">
        2023 Zodhya - All Rights Reserved.
      </p>
      <Notification
        open={openNoti}
        handleClose={handleClose}
        errorMessage={errorMessage}
        severity={severity}
      ></Notification>
    </div>
  );
};

export default Signup;

{
  /* <TextField
            id="phone"
            name="phone"
            label="Enter Phone Number"
            variant="filled"
            type="number"
            fullWidth
            onChange={changeHandler}
            value={phone}
          ></TextField> */
}
