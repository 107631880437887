export const convertDate = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hour = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  return `${year}${month}${day}${hour}${minutes}`;
};

export const convertTimeStampToDate = (foo) => {
  let date = new Date(
    foo.slice(0, 4),
    foo.slice(4, 6) - 1,
    foo.slice(6, 8),
    foo.slice(8, 10),
    foo.slice(10, 12),
    foo.slice(12, 14)
  );
  return date;
};
