import React, { useEffect, useState } from "react";

const Timmer = (props) => {
  const { sec, setSec } = props;

  console.log();

  useEffect(() => {
    let interval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  return <span>{sec}</span>;
};

export default Timmer;
