import { TextField, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";
import PasswordChecklist from "react-password-checklist";

const CustomBtn = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  fontSize: "1rem",
  background:
    "linear-gradient(90deg, rgba(234,92,84,1) 0%, rgba(239,132,58,1) 42%, rgba(239,198,94,1) 100%)",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea5c54",endColorstr="#efc65e",GradientType=1)',
  marginTop: "5px",
}));

const ForgetPass = (props) => {
  const [username, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1);
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const changeHandler = (e) => {
    let val = e.target.value;
    let name = e.target.name;

    switch (name) {
      case "Username":
        setUserName(val);
        break;
      case "code":
        setCode(val);
        break;
      case "newPass":
        setPassword(val);
        break;
      case "confirmPass":
        setConfirmPass(val);
        break;
      default:
    }
  };

  const getUser = () => {
    return new CognitoUser({
      Username: username.toUpperCase(),
      Pool: UserPool,
    });
  };

  const sendCode = (e) => {
    e.preventDefault();
    setLoading(true);
    getUser().forgotPassword({
      onSuccess: (data) => {
        setLoading(false);
      },
      onFailure: (err) => {
        console.error("onFailue", err);
        setLoading(false);
      },
      inputVerificationCode: (data) => {
        setMessage(data.CodeDeliveryDetails.Destination);
        setLoading(false);
        setStage(2);
      },
    });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (confirmPass !== password) {
      alert("password not matching");
      setLoading(false);
      return;
    }
    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        setLoading(false);
        alert("Change successful login with new password");
        props.setForgetPass(false);
      },
      onFailure: (err) => {
        setLoading(false);
        alert(err.message);
      },
    });
  };

  return (
    <div>
      {stage === 1 && (
        <>
          <div className="text-left">
            <h1 className="text-3xl mb-2 font-semibold">
              Forget your password ?
            </h1>
            <p className="w-[85%] mb-3">
              Enter your Username below and we will send an email to reset your
              password
            </p>
          </div>
          <form onSubmit={sendCode}>
            <TextField
              id="userName"
              name="Username"
              label="Enter your Username"
              required={true}
              variant="filled"
              fullWidth
              size="small"
              onChange={changeHandler}
            ></TextField>

            <CustomBtn
              type="submit"
              variant="contained"
              className="w-11/12 "
              loading={loading}
            >
              Reset my password
            </CustomBtn>
          </form>
        </>
      )}
      {stage === 2 && (
        <>
          <p className="mb-2">
            We have sent password reset code by email to {message}. Enter it
            below to reset your password{" "}
          </p>
          <form onSubmit={resetPassword}>
            <div className="flex flex-col gap-2">
              <TextField
                id="code"
                name="code"
                label="Enter your code"
                required={true}
                variant="filled"
                fullWidth
                size="small"
                onChange={changeHandler}
              ></TextField>
              <TextField
                id="newPass"
                name="newPass"
                label="Enter new password"
                onChange={changeHandler}
                required={true}
                variant="filled"
                fullWidth
                size="small"
              ></TextField>
              <TextField
                id="confirmPass"
                name="confirmPass"
                label="Confirm password"
                required={true}
                variant="filled"
                fullWidth
                size="small"
                onChange={changeHandler}
              ></TextField>
            </div>

            <CustomBtn
              type="submit"
              variant="contained"
              //   sx={{ fontSize: "1rem", mt: "16px" }}
              className="w-11/12 "
              loading={loading}
            >
              change password
            </CustomBtn>
          </form>
          <div className="w-[100%] ml-4 mt-3">
            <PasswordChecklist
              rules={[
                "capital",
                "match",
                "specialChar",
                "minLength",
                "number",
                "lowercase",
              ]}
              minLength={8}
              value={password}
              valueAgain={confirmPass}
            />
          </div>
        </>
      )}
      <div className="text-left  mx-auto mt-3">
        <span
          onClick={() => {
            props.setForgetPass(false);
          }}
          className="underline text-blue-600 cursor-pointer"
        >
          Login here
        </span>
      </div>
    </div>
  );
};

export default ForgetPass;
