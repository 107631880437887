export const convert = (originalDate, showTime) => {
  // Create a Date object with the desired date
  // This creates a Date object for the current date and time

  // Define arrays to map weekday and month indices to their short names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day of the week and extract its abbreviation
  const date = originalDate.getDate();

  // Get the month and extract its abbreviation
  const month = months[originalDate.getMonth()];

  // Extract the last 2 digits of the year
  const year = String(originalDate.getFullYear()).slice(-2);

  // Get the time components (hours and minutes)
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");

  // Combine the components into the desired format
  if (showTime === true) {
    return `${date}${month}'${year} ${hours}:${minutes}`;
  } else {
    return `${date}${month}'${year}`;
  }
};
