import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

const Selector = (props) => {
  return (
    <div className={`inline-block  + ${props.className}`}>
      <Box className="flex justify-center items-center bg-white px-2 rounded-lg">
        <p className="whitespace-nowrap mr-3 text-sm text-[#818181] inline-block">
          {props.name.toUpperCase()}
        </p>
        {/* sx={{ width: "120px", color: "#818181" }} */}
        <FormControl
          sx={{ color: "#818181", minWidth: "120px" }}
          variant="standard"
          size="small"
        >
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={props.select}
            placeholder="hello"
            // label={props.name}
            disableUnderline={true}
            onChange={props.handleChange}
            sx={{
              backgroundColor: "white",
            }}
            displayEmpty
            inputProps={{
              sx: {
                color: "#545454",
                mt: "5px",
                "&:focus": { backgroundColor: "white" },
              },
            }}
            required={props.required ? true : false}
          >
            {props.values.map((val, i) => {
              return (
                <MenuItem
                  value={val}
                  key={props.names[i]}
                  sx={{ color: "#545454" }}
                >
                  {" "}
                  {props.names[i]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default Selector;
