import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import createInstance from "../api";

const CountdownTimer = ({ initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      return;
    }

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  return <>{seconds}</>;
};

const VerifyPayments = () => {
  let api = createInstance();
  let { transId } = useParams();
  const navigate = useNavigate();

  const [notLoading, setNotLoading] = useState(false);
  const [success, setSuccess] = useState(true);

  const verifyPending = async () => {
    const interval = setInterval(async () => {
      const res = await api.post("/paymentstatus", { transactionId: transId });
      let code = res.data;
      if (code === "PAYMENT_SUCCESS") {
        setSuccess(true);
        clearImmediate(interval);
      } else if (code === "PAYMENT_ERROR") {
        setSuccess(false);
        clearImmediate(interval);
      }
    }, 10000);

    await new Promise((resolve, reject) => {
      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 60000);
    });

    setSuccess(false);
  };

  const getData = async () => {
    const res = await api.post("/paymentstatus", { transactionId: transId });
    let { code } = res.data;
    if (code === "PAYMENT_SUCCESS") {
      setSuccess(true);
    } else if (code === "PAYMENT_ERROR") {
      setSuccess(false);
    } else if (code === "PAYMENT_PENDING") {
      await verifyPending();
    }
    setNotLoading(true);

    setTimeout(() => {
      navigate("/bill", { replace: true });
    }, 5000);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center flex-col gap-2">
      {notLoading ? (
        <>
          {success ? (
            <>
              <CheckCircle sx={{ color: "green", fontSize: "10rem" }} />
              <h1>
                Transaction successful redirecting in{" "}
                <CountdownTimer initialSeconds={5} /> seconds{" "}
              </h1>
            </>
          ) : (
            <>
              <Cancel sx={{ color: "red", fontSize: "10rem" }} />
              <h1>
                Transaction unsuccessful redirecting in{" "}
                <CountdownTimer initialSeconds={5} /> seconds{" "}
              </h1>
            </>
          )}
        </>
      ) : (
        <>
          <CircularProgress size={"5rem"} sx={{ color: "#F08528" }} />
          <div>
            <h1 className="relative w-[max-content] font-mono before:absolute before:inset-0 before:animate-typewriter before:bg-[#EDEDED]">
              Wait verifying payment status....
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyPayments;
