import { useDispatch, useSelector } from "react-redux";
import createInstance from "./api";
import { useEffect, useState } from "react";
import {
  setClientData,
  setGateWayData,
  deleteClientData,
  deleteGateWayData,
} from "./redux/actions/index";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

import { Account } from "./components/Account";

import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";

import { useServiceWorker } from "./useServiceWorker";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import LoginSignupPage from "./pages/LoginSignupPage";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1800,
    },
  },
});

function App() {
  let api = createInstance();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const [loading, setLoading] = useState(true);

  const login = useSelector((state) => state.loginState);

  const userData = useSelector((state) => state.clientData);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });

    dispatch(deleteClientData());
    dispatch(deleteGateWayData());
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showReload && waitingWorker) {
      setShow(true);
    } else setShow(false);
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    let jwt = JSON.parse(localStorage.getItem("jwtToken"));
    let orgID = JSON.parse(localStorage.getItem("orgID"));
    setLoading(true);
    const getData = async () => {
      try {
        if (jwt && orgID) {
          let [userData, gateWayDat] = await Promise.all([
            api.post("/client", { OrganizationID: orgID }),
            api.post("/clientgateway", { OrganizationID: orgID }),
          ]);
          localStorage.setItem("userData", JSON.stringify(userData.data));
          dispatch({ type: "LOGIN_SUCCESS" });
          dispatch(setClientData(userData.data));

          dispatch(setGateWayData(gateWayDat.data));
          setLoading(false);
        } else {
          dispatch(deleteClientData());
          dispatch(deleteGateWayData());
          dispatch({ type: "LOGOUT" });
          setLoading(false);
        }
      } catch (e) {
        dispatch(deleteClientData());
        dispatch(deleteGateWayData());
        dispatch({ type: "LOGOUT" });
        window.location = "/";
        setLoading(false);
      }
    };

    getData();
  }, [login]);

  return (
    <>
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message="Update available"
        action={
          <Button color="secondary" size="small" onClick={() => reloadPage()}>
            Update
          </Button>
        }
      ></Snackbar>
      {loading ? (
        <Loader></Loader>
      ) : login ? (
        <PrimeReactProvider>
          <ThemeProvider theme={theme}>
            <Account>
              {typeof userData === "string" ? (
                <div className="flex flex-col h-screen justify-center items-center relative">
                  <div className="absolute top-5 right-5">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Button>
                  </div>
                  <img alt="logo" src={require("./asset/icon.png")}></img>
                  <h1 className="text-lg text-center">
                    You will get access to the dashboard once Soul is installed
                    at your location.
                  </h1>
                </div>
              ) : (
                <Dashboard />
              )}
            </Account>
          </ThemeProvider>
        </PrimeReactProvider>
      ) : (
        <div className=" flex min-h-screen items-center justify-center">
          <Account>
            <LoginSignupPage></LoginSignupPage>
          </Account>
        </div>
      )}
    </>
  );
}

export default App;
