import React, { useState } from "react";
import { Modal, Box, Divider } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ChangePassword from "./ChangePassword";
import Notification from "./Notification";
import ChangeEmail from "./ChangeEmail";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  border: "0",
};

const SettingModal = (props) => {
  const [openNoti, setOpenNoti] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const showSnackBar = () => {
    setOpenNoti(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const type = () => {
    switch (props.type) {
      case "changePassword":
        return (
          <ChangePassword
            handleClose={props.handleClose}
            showSnackBar={showSnackBar}
            setErrorMessage={setErrorMessage}
            setSeverity={setSeverity}
          ></ChangePassword>
        );
      case "changeEmail":
        return (
          <ChangeEmail
            handleClose={props.handleClose}
            showSnackBar={showSnackBar}
            setErrorMessage={setErrorMessage}
            setSeverity={setSeverity}
          ></ChangeEmail>
        );
      default:
        return <div></div>;
    }
  };
  return (
    <>
      <Notification
        open={openNoti}
        handleClose={handleCloseSnack}
        errorMessage={errorMessage}
        severity={severity}
      ></Notification>

      <Modal open={props.open} onClose={props.handleClose}>
        <Box sx={style} className="md:w-fit w-11/12 flex justify-center">
          <button
            onClick={props.handleClose}
            className="absolute right-10 top-5"
          >
            <Cancel sx={{ color: "red" }}></Cancel>
          </button>
          {type()}
        </Box>
      </Modal>
    </>
  );
};

export default SettingModal;
