import {
  Divider,
  Box,
  useScrollTrigger,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Input,
  TableBody,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./Reports.css";
import Selector from "../components/Selector";
import { useSelector } from "react-redux";

let monthDict = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

const CusTable = (props) => {
  let now = new Date();
  const [reportData, setReportData] = useState([]);
  const [month, setMonth] = useState(null);

  const gateWayData = useSelector((state) => {
    return state.gateWayData;
  });

  const gateWayIds = {};
  gateWayData.forEach((gate) => {
    gateWayIds[gate.gatewayID] = gate;
  });

  const [gateway, setGateWay] = useState("");

  const getLink = async (objectKey) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: S3_BUCKET,
        Key: objectKey,
      };

      s3.getObject(params, (err, data) => {
        if (err) {
          return reject(null);
        } else {
          // `data.Body` contains the PDF file data.
          // console.log("File downloaded successfully:", data);

          // To create a download link
          const blob = new Blob([data.Body], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          return resolve(url);
        }
      });
    });
  };

  useEffect(() => {
    setMonth(null);

    const getFiles = async () => {
      if (gateway !== "") {
        let gateData = gateWayIds[gateway];

        const date = new Date(
          gateData.CreatedAt._seconds * 1000 +
            gateData.CreatedAt._nanoseconds / 1000000
        );

        const currDate = new Date();

        // let startMonth = date.getMonth() + 1;

        // let currMonth = new Date().getMonth() + 1;
        // let currYear = new Date().getFullYear();

        let files = [];

        while (date < currDate.setDate(1)) {
          let dateMonth = date.getMonth() + 1;
          let dateYear = date.getFullYear();

          if (dateMonth <= 9) {
            dateMonth = "0" + dateMonth;
          }
          let link = null;
          try {
            link = await getLink(
              gateway + "/" + dateYear + "" + dateMonth + ".pdf"
            );
          } catch (e) {
            link = null;
          }
          files.push({
            gatewayID: gateway,
            reportMonth: dateMonth,
            reportYear: dateYear,
            link,
          });
          date.setMonth(date.getMonth() + 1);
        }

        files.reverse();
        setReportData(files);
      }
    };

    getFiles();
  }, [gateway]);

  const handleGateWay = (e) => {
    setGateWay(e.target.value);
  };

  const mapData = (dat) => {
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {dat.gatewayID}
        </TableCell>
        <TableCell align="center">
          {monthDict[dat.reportMonth] + " " + dat.reportYear}
        </TableCell>
        <TableCell align="center">
          {dat.link !== null ? (
            <Button
              color="success"
              variant="contained"
              size="small"
              href={dat.link}
              target="_black"
              download={`Report`}
              rel="noreferrer"
            >
              Download
            </Button>
          ) : (
            <p>No report available</p>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="w-[95%] mx-auto">
      <Box className="overflow-x-auto">
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#F08528",
                      fontWeight: "bold",
                      py: 0,
                    }}
                  >
                    <FormControl variant="standard">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={gateway}
                        onChange={handleGateWay}
                        size="small"
                        displayEmpty
                        renderValue={
                          gateway !== "" ? undefined : () => "Select Gateway"
                        }
                        label="Age"
                      >
                        {Object.keys(gateWayIds).map((id, i) => {
                          return (
                            <MenuItem value={id} key={i}>
                              {gateWayIds[id].name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ backgroundColor: "#F08528", fontWeight: "bold" }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ backgroundColor: "#F08528", fontWeight: "bold" }}
                  >
                    Download Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{reportData.map(mapData)}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};

AWS.config.update({
  accessKeyId: "AKIAQRZ7237UCEGYDM5O",
  secretAccessKey: "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",
});
const S3_BUCKET = "soulreport";
const REGION = "ap-south-1";

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const Reports = () => {
  return (
    <div className="w-11/12 mx-auto">
      <div className="flex justify-between items-end flex-wrap gap-2">
        <h1 className="text-4xl   text-[#545454] mt-10">Reports</h1>

        <div className="flex gap-2 flex-wrap items-center">
          {/* <Selector
            values={Object.keys(gateWayIds)}
            names={gateWayName}
            select={gateway}
            name="Gateway"
            handleChange={handleGateWay}
            required={true}
          ></Selector> */}
          {/* <Box className="flex items-center bg-white p-1 pl-2 rounded-lg w-fit  ">
            <p className="whitespace-nowrap mr-2 text-sm text-[#818181]">
              MONTH
            </p>
            <Calendar
              value={month}
              onChange={(e) => setMonth(e.value)}
              panelClassName="container"
              className="h-7 w-[100px]"
              maxDate={now}
              view="month"
              dateFormat="mm/yy"
            />
          </Box> */}
          {/* <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#F97316",
              height: "30px",

              ":hover": { backgroundColor: "#F97316" },
            }}
          >
            Search
          </Button> */}
        </div>
      </div>
      <Divider color="#818181" style={{ marginTop: "10px" }} />
      <div className="mt-4">
        <CusTable />
      </div>
    </div>
  );
};

export default Reports;
