import React, { useState, useContext } from "react";
import Notification from "../components/Notification";
import { AccountContext } from "../components/Account";
import {
  InputAdornment,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  FilledInput,
  Grid,
  Backdrop,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import logo from "../asset/icon.png";
import createInstace from "../api";
import { useDispatch } from "react-redux";
import Loader from "../components/Loader";
import ForgetPass from "./ForgetPass";
import classes from "./Login.module.css";

// import CustomBtn from "../components/CustomBtn";

const LogInBtn = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  fontSize: "1rem",
  background:
    "linear-gradient(90deg, rgba(234,92,84,1) 0%, rgba(239,132,58,1) 42%, rgba(239,198,94,1) 100%)",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea5c54",endColorstr="#efc65e",GradientType=1)',
  marginTop: "15px",
}));

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [openNoti, setOpenNoti] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forgetPass, setForgetPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const showSnackBar = () => {
    setOpenNoti(true);
  };

  const { authenticate } = useContext(AccountContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const changeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "userName") {
      setUserName(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    authenticate(userName, password)
      .then(async (data) => {
        localStorage.setItem(
          "jwtToken",
          JSON.stringify(data.getIdToken().getJwtToken())
        );
        localStorage.setItem(
          "orgID",
          JSON.stringify(data.accessToken.payload.sub)
        );
        let api = createInstace();

        let orgID = JSON.parse(localStorage.getItem("orgID"));
        // console.log(jwt);
        let userData = await api.post("/client", { OrganizationID: orgID });
        localStorage.setItem("userData", JSON.stringify(userData.data));
        dispatch({ type: "LOGIN_SUCCESS" });

        dispatch({ type: "SET_CLIENT_DATA", payload: userData.data });

        setLoading(false);
      })
      .catch(async (err) => {
        // console.log(err);
        setErrorMessage(err.message);
        if (err.message === "Network error") {
          setErrorMessage("Internet connectivity issue");
        }
        showSnackBar();
        setLoading(false);
      });
    setUserName("");
    setPassword("");
  };

  return (
    <>
      <Grid
        container
        justify="flex"
        alignItems="center"
        sx={{ height: "inherit" }}
        className="text-center"
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <h1 className="lg:text-5xl md:w-1/2 font-bold mx-auto w-10/12 lg:text-left text-2xl text-center sm:mb-4">
            <h1 className="text-[#F97316] lg:text-6xl text-3xl">Soul</h1>
            Operate your business smarter.<br></br>Track inefficiencies in
            realtime.
          </h1>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="text-center align-middle inline-block h-100 mt-5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-5 w-11/12 mx-auto md:w-2/3">
            <img
              className="mx-auto mb-3 p-3 h-16 "
              src={logo}
              alt="zodhyaLogo"
            ></img>
            {forgetPass ? (
              <ForgetPass setForgetPass={setForgetPass}></ForgetPass>
            ) : (
              <form onSubmit={submitHandler}>
                {/* <h1 className="text-left text-2xl mb-3 font-bold">
                Log in to your account
              </h1> */}
                <div>
                  <TextField
                    id="userName"
                    name="userName"
                    label="Enter Username"
                    required={true}
                    variant="filled"
                    margin="normal"
                    fullWidth
                    autoFocus={true}
                    onChange={changeHandler}
                    value={userName}
                  ></TextField>
                </div>
                <div>
                  <FormControl
                    fullWidth
                    margin="normal"
                    variant="filled"
                    required={true}
                  >
                    <InputLabel htmlFor="filled-adornment-password">
                      Password
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      value={password}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={changeHandler}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
                <div className="my-3">
                  <LogInBtn
                    type="submit"
                    variant="contained"
                    className="w-11/12 "
                    loading={loading}
                  >
                    Log In
                  </LogInBtn>

                  <div className="text-center mt-3 text-[#545454] ">
                    <div>
                      Don't have an account ?{" "}
                      <span
                        onClick={() => props.setIsLogin(false)}
                        className="underline text-blue-600 cursor-pointer text-left"
                      >
                        signup
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setForgetPass(true);
                      }}
                      className="underline text-blue-600 cursor-pointer"
                    >
                      Forgot password
                    </div>
                  </div>
                </div>
              </form>
            )}

            <hr className="h-px my-3 mb-4 bg-gray-200 border-0"></hr>
            <p className="text-gray-500 dark:text-gray-400">
              2023 Zodhya - All Rights Reserved.
            </p>
          </div>
        </Grid>
      </Grid>
      <Notification
        open={openNoti}
        handleClose={handleClose}
        errorMessage={errorMessage}
        severity="error"
      ></Notification>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        className={classes.blur}
        open={loading}
        onClick={handleClose}
      >
        <Loader />
      </Backdrop>
    </>
  );
};

export default Login;
