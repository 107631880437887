import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { convertTimeStampToDate } from "../../helperFunc/convertDate";
import { convert } from "../../helperFunc/graphDate";
import DownloadMenu from "./DownloadMenu";
import ApexCharts from "apexcharts";
import DataImage from "./DataImage";
import DomToImage from "dom-to-image";

import mergeImages from "merge-images";

const EnergyGraph = (props) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);

  console.log(props.data);
  const [csvData, setCsvData] = useState([]);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);

  useEffect(() => {
    const dat = [];
    const dates = [];
    const csvDat = [];

    props.data.forEach((data) => {
      let foo = data.Timestamp.toString();
      let date = convertTimeStampToDate(foo);

      let units = (parseFloat(data[props.nodeSelect]?.Mean_Eact) || 0).toFixed(
        2
      );

      dates.push(date);
      dat.push({
        x: convert(date, props.showTime),
        y: units,
      });
      csvDat.push([convert(date, props.showTime), units].join(","));

      // i++;
    });
    dat.reverse();
    setData(dat);
    setCsvData(csvDat);
    setDate(dates);
  }, [props.data, props.nodeSelect]);

  const chartDetails = {
    options: {
      chart: {
        id: "energyConsumedGraph",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          show: true,
          rotate: 0,
        },
        title: {
          text: "Time",
        },
      },
      yaxis: {
        title: {
          text: "Units (kWh)",
        },
      },
      stroke: {
        colors: ["transparent"],
        // width: 10,
      },
      // "asiasioas"
      plotOptions: {
        bar: {
          columnWidth: "40%",
          // barWidth: "50%",
          // barHeight: "80%",
        },
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Units",
        data: data,
        color: "#298F9D",
      },
    ],
  };

  if (!display) {
    return <></>;
  }

  // const downloadImg = async () => {
  //   let ele = document.getElementById("print");
  //   DomToImage.toJpeg(ele, {
  //     width: ele.clientWidth * 2,
  //     height: ele.clientHeight * 2,
  //     style: {
  //       transform: "scale(" + 2 + ")",
  //       transformOrigin: "top left",
  //     },
  //   }).then(function (dataUrl) {
  //     ApexCharts.exec("energyConsumedGraph", "dataURI", { scale: 2 }).then(
  //       ({ imgURI }) => {
  //         mergeImages(
  //           [
  //             { src: dataUrl, x: 0, y: 0 },
  //             { src: imgURI, y: 200, x: 0 },
  //           ],
  //           { height: 1000 }
  //         ).then((b64) => {
  //           console.log(b64);
  //           var a = document.createElement("a");
  //           a.href = b64;
  //           a.download = "Image.png";
  //           a.click();
  //         });
  //       }
  //     );
  //   });
  // };

  const downloadImg = () => {
    ApexCharts.exec("energyConsumedGraph", "dataURI", { scale: 3 }).then(
      ({ imgURI }) => {
        const anchor = document.createElement("a");
        anchor.href = imgURI;
        anchor.download = "EnergyGraph.png";
        anchor.click();
      }
    );
  };

  const CsvDownload = () => {
    const header = "Date,Units";
    let data = [header, ...csvData];

    let csv = data.join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", "EnergyConsumptionData.csv");
    a.click();
  };

  return (
    <div className="relative">
      <Chart
        options={chartDetails.options}
        series={chartDetails.series}
        type="bar"
        height={props.graphHeight}
      />
      <DownloadMenu downloadImg={downloadImg} downloadCsv={CsvDownload} />

      {/* {data.length !== 0 && (
        <DataImage
          startDate={data[0].x}
          endDate={data[data.length - 1].x}
          node={props.nodeSelect}
          gateway={props.data[0].GatewayID}
          graphName={"Energy"}
        />
      )} */}
    </div>
  );
};

export default EnergyGraph;
