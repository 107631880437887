export const clientData = (state = {}, action) => {
  switch (action.type) {
    case "SET_CLIENT_DATA":
      return action.payload;
    case "REMOVE_CLIENT_DATA":
      return {};
    default:
      return state;
  }
};

export const loginState = (state = false, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return true;
    case "LOGOUT":
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("userData");
      localStorage.removeItem("orgID");
      localStorage.removeItem("login");
      return false;
    default:
      return state;
  }
};

export const gateWayData = (state = [], action) => {
  switch (action.type) {
    case "SET_GATEWAY_DATA":
      return action.payload;
    case "DELETE_GATEWAY_DATA":
      return [];
    default:
      return state;
  }
};

export const mailSent = (state = false, action) => {
  switch (action.type) {
    case "MAIL_SENT":
      return true;
    case "MAIL_UNSENT":
      return false;
    default:
      return state;
  }
};

export const invoice = (state = null, action) => {
  switch (action.type) {
    case "SET_INVOICE":
      return action.payload;
    default:
      return state;
  }
};
