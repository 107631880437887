import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableBody,
  Paper,
  styled,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import GateWayDetailsModal from "../components/GateWayDetailsModal";
import editIcon from "../asset/edit.png";
import { setGateWayData } from "../redux/actions";
import SettingModal from "../components/SettingModal";
import CusModal from "../components/CusModal";
import createInstance from "../api";
import PresetsModal from "../components/PresetsModal";

function Row(props) {
  const { gate } = props;


  const CusRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    height: "5rem",
  }));

  return (
    <React.Fragment>
      <CusRow sx={{ "& > *": { border: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            color: "#545454",
            fontSize: "1rem",
          }}
          className="whitespace-nowrap"
        >
          {gate.name}{" "}
          <button
            type="button"
            onClick={(e) => {
              props.setGatewayname(gate.name);
              props.setGatewayId(gate.gatewayID);
              props.handleOpenEdit();
            }}
          >
            <img
              src={editIcon}
              alt="edit"
              className="inline-block h-[19px] ml-[10px] mb-1"
            ></img>
          </button>
        </TableCell>
        <TableCell align="left" sx={{ color: "#545454", fontSize: "1rem" }}>
          {gate.gatewayID}
        </TableCell>
        {/* <TableCell align="left">
          <div className="text-[#545454] w-[100px]">
            <span>
              <img
                src={active ? Active : Inactive}
                className="inline-block h-[15px] mr-[5px]"
                alt="activeInactiveIcon"
              ></img>
            </span>{" "}
            {active ? "Paid" : "Unpaid"}
          </div>
        </TableCell> */}
        <TableCell
          sx={{ borderBottomRightRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "#818181",
              borderColor: "#818181",
              borderRadius: "10px",
              fontSize: "smaller",
              textAlign: "center",
              minWidth: "90px",
            }}
            size="small"
            onClick={(e) => {
              props.setGatewayname(gate.name);
              props.setGatewayId(gate.gatewayID);
              props.handleOpen();
            }}
          >
            Edit Gateway
          </Button>
        </TableCell>
      </CusRow>
    </React.Fragment>
  );
}


const Profile = () => {
  let api = createInstance();

  const invoice = useSelector((state) => state.invoice);
  let paymentStatus = invoice?.paymentstatus ?? null;
  let dueDate = invoice?.due ?? null;

  dueDate = new Date(dueDate);

  const clientData = useSelector((state) => state.clientData);
  const gateWayData = useSelector((state) => state.gateWayData);
  const styleOfHeader = {
    color: "#818181",
    fontSize: "0.8rem",
    fontWeight: "light",
  };

  const dispatch = useDispatch();


  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [openPassword, setOpenPassword] = useState(false);
  const [emailChangeOpen, setEmailChangeOpen] = useState(false);
  const [openEditName, setOpenEditName] = useState(false);
  const [openPreset, setOpenPreset] = useState(false);

  const [gatewayName, setGatewayname] = useState("");
  const [gatewayId, setGatewayId] = useState("");

  const handleOpenPassword = () => {
    setOpenPassword(true);
  };
  const handleClosePassword = () => {
    setOpenPassword(false);
  };

  const handleOpenPreset = () => {
    setOpenPreset(true);
  };
  const handleClosePreset = () => {
    setOpenPreset(false);
  };

  const handleOpenEmail = () => {
    setEmailChangeOpen(true);
  };
  const handleCloseEmail = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setEmailChangeOpen(false);
  };


  const handleEditOpen = () => {
    setOpenEditName(true);
  };
  const handleEditClose = () => {
    setOpenEditName(false);
  };

  const changeGatewayName = async (newGatewayName) => {
    const res = await api.post("/updategatewayname", {
      gatewayID: gatewayId,
      gatewayname: newGatewayName,
    });
    if (res.status === 200) {
      let data = [...gateWayData];
      data = data.map((dat) => {
        if (dat.gatewayID === gatewayId) {
          console.log(dat);
          dat.name = newGatewayName;
        }
        return dat;
      });
      //make names chote se bada
      // data = data.sort((a, b) => {
      //   let fa = a.name.toLowerCase();
      //   let fb = b.name.toLowerCase();

      //   if (fa > fb) {
      //     return ascName ? 1 : -1;
      //   }
      //   if (fb > fa) {
      //     return ascName ? -1 : 1;
      //   }
      //   return 0;
      // });
      dispatch(setGateWayData([...data]));
      setOpenEditName(false);
    }
  };

  return (
    <>
      <CusModal
        open={openEditName}
        handleClose={handleEditClose}
        currentName={gatewayName}
        text={"CHANGE GATEWAY NAME"}
        changeName={changeGatewayName}
      ></CusModal>

      <GateWayDetailsModal
        open={open}
        gatewayName={gatewayName}
        gatewayID={gatewayId}
        handleOpen={handleOpen}
        handleClose={handleClose}
      ></GateWayDetailsModal>

      <SettingModal
        open={openPassword}
        handleOpen={handleOpenPassword}
        type="changePassword"
        handleClose={handleClosePassword}
      ></SettingModal>

      <SettingModal
        open={emailChangeOpen}
        handleOpen={handleOpenEmail}
        type="changeEmail"
        handleClose={handleCloseEmail}
      ></SettingModal>

      <PresetsModal
        open={openPreset}
        handleOpen={handleOpenPreset}
        type="changePreset"
        handleClose={handleClosePreset}
      ></PresetsModal>



      <Box className="w-11/12 mx-auto pt-11">
        <div className="flex justify-between flex-wrap gap-2">
          <h1 className="text-3xl  ml-6 text-[#545454] leading-none whitespace-nowrap flex items-center gap-2 flex-wrap">
            <span>Welcome,</span>
            <span>{clientData.username}!</span>
          </h1>
        </div>

        <Divider color="#818181" style={{ marginTop: "24px" }}></Divider>

        <div className="w-[90%] mx-auto mt-2 bg-white rounded-lg p-4 break-all">
          <h1 className="text-lg text-[#545454] font-medium">
            Account details
          </h1>
          <Divider></Divider>
          <div className="my-2 mb-3 grid grid-cols-2 gap-2 ">
            <div>
              <span className="font-medium">Username:</span>{" "}
              {clientData.username}
            </div>
            <div>
              <span className="font-medium">Email:</span> {clientData.Email}
            </div>
            <div>
              <span className="font-medium">OrgID:</span>{" "}
              {clientData.OrganizationID}
            </div>
            <div>
              {/* <span className="font-medium">GSTIN:</span> {clientData.GSTIN} */}
            </div>
          </div>


          <div className="flex gap-3 ">

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenPassword}
            >
              Change Password
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenEmail}
            >
              Change Email
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to={"/bill"}
            >
              View bill
            </Button> */}

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenPreset}
            >
              Set Presets
            </Button>
          </div>
        </div>
        <Box className="w-[90%] mx-auto mt-2 overflow-x-auto">
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <TableContainer
              component={Paper}
              sx={{ backgroundColor: "#EDEDED" }}
            >
              <Table
                aria-label="collapsible table"
                sx={{ borderCollapse: "separate", borderSpacing: "0 0.5em" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={styleOfHeader} className="whitespace-nowrap">
                      GATEWAY NAME{" "}
                    </TableCell>
                    <TableCell align="left" sx={styleOfHeader}>
                      GATEWAY ID
                    </TableCell>
                    {/* <TableCell align="left" sx={styleOfHeader}>
                      PAYMENT STATUS
                    </TableCell> */}
                    <TableCell sx={styleOfHeader}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gateWayData.map((gate) => (
                    <Row
                      key={gate.gatewayID + "profile"}
                      gate={gate}
                      handleOpen={handleOpen}
                      handleOpenEdit={handleEditOpen}
                      setGatewayname={setGatewayname}
                      setGatewayId={setGatewayId}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
