import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex gap-5 flex-col px-5 mt-16 mb-3">
      <h1 className="text-3xl font-semibold underline">TERMS AND CONDITIONS</h1>
      <div>
        <h2 className="text-xl font-medium">GENERAL</h2>
        <p>
          These Terms and Conditions written on this webpage shall manage your
          use of our website, Zodhya Technologies Private Limited accessible at{" "}
          <a
            href=" https://www.zodhyatech.com"
            className="underline text-blue-600 cursor-pointer"
          >
            https://www.zodhyatech.com
          </a>
          . These Terms will be applied fully and affect to your use of this
          Website. By using this Platform, you expressly agree to be bound by
          the Terms. So please read these carefully before continuing to use the
          Website. In the following terms and conditions, the terms ‘the
          Company’ refers to Zodhya Technologies Private Limited; "You" and
          "Your" refers to you, the person log on this website and compliant to
          the Company’s terms and conditions and the ‘website’ refers to{" "}
          <a
            href=" https://www.zodhyatech.com"
            className="underline text-blue-600 cursor-pointer"
          >
            https://www.zodhyatech.com
          </a>
          .
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">
          {" "}
          ACCEPTANCE OF TERMS AND CONDITIONS
        </h2>
        <p>
          By accessing or using this website you agree to be legally bound by
          the Terms of Use and all terms and conditions contained or referenced
          herein or any additional terms and conditions set forth on this
          website. Any accessing, browsing, or otherwise using the this website
          indicates your agreement to these Terms and conditions, the privacy
          policy of Zodhya Technologies Private Limited and any other policies
          or guidelines that may be applicable at the time of your access and
          usage which may be updated from time to time (collectively, the
          “Agreement”). If you do NOT agree to all of these terms, you should
          NOT access or use this website.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">MODIFICATION OF TERMS</h2>
        <p>
          These Terms and conditions may be amended by Zodhya Technologies
          Private Limited at any time. Such amended Terms of Use shall be
          effective upon posting. It reserves the right to discontinue or make
          changes or updates with respect to the website or its content, it’s
          term and conditions and Linking policy at any point of time without
          notice. By continuously linking to our Website, you agree to be bound
          to and follow these linking terms and conditions.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">PRIVACY POLICY/ DISCLAIMERS</h2>
        <p>
          These Terms and Conditions are subject to the Privacy Policy as posted
          on this website. All information (including, without limitation, text,
          images, graphics, links, and other materials) on the website is
          provided "as is" and "as available". The Company hereby expressly
          disclaims to extent permitted by law any representations or warranties
          of any kind, express or implied, relating to this website and its use.
          Nothing in this disclaimer will warrant or make any representations
          that :
          <ul className="list-disc list-inside">
            <li>the Web Site will meet your requirements.</li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">COOKIES</h2>
        <p>
          We use cookies on our site to analyze traffic, enhance your
          experience, and provide you with tailored content. For more
          information visit our privacy policy.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">YOUR CONTENT</h2>
        <p>
          You agree that, “Your Content” shall mean any audio, video text,
          images or other material you choose to display on this Website. By
          displaying Your Content, you grant Company a non-exclusive,
          irrevocable, sub licensable license to use, reproduce, adapt, publish,
          translate and distribute it in any and all media. You are entitled to
          post the Comments on our website and have all necessary licenses and
          consents to do so and it will not in any manner be a liability to
          Zodhya Technologies Private Limited. You warrant and represent that:
          <ul className="list-disc  list-inside">
            <li>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party.
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy or cause any kind of dispute or controversy which may
              likely cause legal actions.
            </li>
            <li>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">RESTRICTIONS</h2>
        <p>
          You are specifically restricted from all of the following:
          <ul className="list-disc  list-inside">
            <li>
              No material which is published on this website should be
              republished by you anywhere or on any other online/ offline in the
              name of the Company.
            </li>
            <li>
              No material which is published on this website should be sold,
              rented or sub-licensed by you under any circumstances on behalf or
              in the name of the Company.
            </li>
            <li>
              Under any circumstances the materials from this website should be
              reproduced, replicated, or copied as any such action is not
              permitted strictly by the Company.
            </li>
            <li>
              No content/ material / information published on this website
              should be redistributed by you under any circumstances.
            </li>
            <li>
              Without prior approval and express written permission, you may not
              create Iframes around our Website or use other techniques that
              alter in any way the visual presentation or appearance of our Web
              site. The Company reserves the right at any time and in its sole
              discretion to request that you remove all links or any particular
              link to our Web site. You agree to immediately remove all links to
              our Web site upon such request. We also reserve the right to amend
              these terms and conditions and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and
              abide by these linking terms and conditions.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">
          THIRD PARTY / SOFTWARE (hyperlinking)
        </h2>
        <p>
          Under any circumstances, the following organizations may link to our
          website without prior written approval:
          <ul className="list-disc  list-inside">
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organizations</li>
            <li>
              Online directory distributors may link to our website in the same
              manner as they hyperlink to the Websites of other listed
              businesses
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </li>
          </ul>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site. The approved
          organizations may hyperlink to our Website as follows:
          <ul className="list-disc  list-inside">
            <li>By use of our corporate name.</li>
            <li>By use of the uniform resource locator being linked to.</li>
            <li>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party’s site.
            </li>
          </ul>
          No use of Zodhya Technologies Private Limited's logo or other artwork
          will be allowed for linking absent a trademark license agreement.
          Zodhya Technologies Private Limited is in no way responsible for the
          content of any site owned by a third party that may be linked to the
          Web Site via hyperlink, whether or not such hyperlink is provided by
          the Web Site or by a third party in accordance with the Terms of Use.
          Any link on our Web Site to another site is not an endorsement of such
          other site and no judgment or warranty is made with respect to the
          accuracy, timeliness, or suitability of the content of any site to
          which the Web Site may link, and we take no responsibility, therefore.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">INDEMNIFICATION</h2>
        <p>
          You hereby indemnify to the fullest extent Zodhya Technologies Private
          Limited from and against any and/or all liabilities, costs, demands,
          causes of action, damages and expenses arising in any way related to
          your breach of any of the provisions of these Terms.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">GOVERNING LAWS</h2>
        <p>
          These terms are governed by and shall be construed in accordance with
          the laws governing in India. Further you agree to the jurisdiction of
          courts located in India, to resolve any dispute, claim or controversy
          that relates to or arises in connection with these terms. All terms
          are in accordance with the aim of undertaking the process of our
          assistance to the client in the most appropriate manner for the
          purpose of meeting the client’s needs in respect to provision of the
          Company’s stated services, in accordance with prevailing laws in
          India.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">SEVERABLITY</h2>
        <p>
          If any provision of these Terms and Conditions are held to be invalid
          or unenforceable, then the invalid or unenforceable provision will be
          replaced by a valid, enforceable provision that most closely matches
          the intent of the original provision and the remaining provisions
          shall be enforced.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">NO WAIVER</h2>
        <p>
          No provision in these Terms of Use will be deemed waived and no breach
          excused, unless such waiver or consent is in writing and signed by the
          Company. Any consent by Zodhya Technologies Private Limited to, or
          waiver of your breach, whether expressed or implied, will not
          constitute consent to, waiver of, or excuse for any other different or
          subsequent breach.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">FORCE MAJEURE</h2>
        <p>
          Zodhya Technologies Private Limited will not be held responsible for
          any delay or failure to comply with its obligations if the delay or
          failure arises from any cause which is beyond it’s reasonable control.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
