import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  styled,
  Divider,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Info, SyncAltOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setGateWayData } from "../redux/actions";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import CustomBtn from "../components/CustomBtn";
import { convertTimeStampToDate } from "../helperFunc/convertDate";
import Active from "../asset/active.png";
import Inactive from "../asset/Inactive.png";
import editIcon from "../asset/edit.png";
import CusModal from "../components/CusModal";
import createInstance from "../api";

function Row(props) {
  let api = createInstance();

  const { gate, searchString } = props;

  const sentMail = useSelector((state) => {
    return state.mailSent;
  });

  const dispatch = useDispatch();

  const [active, isActive] = useState(false);

  // const [sentMail, setMailState] = useState(false);

  useEffect(() => {
    const getActive = async () => {
      let now = new Date();

      const res = await api.post("/lasttimestamp", {
        GatewayID: gate.gatewayID,
      });

      if (res.data !== null && res.data.length === 1) {
        let lastUpdated = convertTimeStampToDate(res.data[0].toString());
        let timePassed = now.getTime() - lastUpdated.getTime();

        if (timePassed > 10 * 60 * 1000) {

          isActive(false);
        } else {

          isActive(true);
        }
      } else {
        isActive(false);
      }
    };
    getActive();
    const intervalCall = setInterval(() => {
      getActive();
    }, 60000);

    return () => {
      clearInterval(intervalCall);
    };
  }, []);
  const CusRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    height: "5rem",
  }));

  let getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: "bold" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </>
    );
  };

  let epi = (
    parseFloat(gate.calculator_parameters.monthyconsumption) /
    parseFloat(
      gate.calculator_parameters.air_conditioned_area * 0.09290303997
    ) /
    (parseFloat(gate.calculator_parameters.requirebenchmark) / 12)
  ).toFixed(2);

  return (
    <React.Fragment>
      <CusRow sx={{ "& > *": { border: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            color: "#545454",
            fontSize: "1rem",
          }}
          className="whitespace-nowrap"
        >
          {getHighlightedText(gate.name, searchString)}{" "}
          <button
            type="button"
            onClick={(e) => {
              props.setGatewayname(gate.name);
              props.setGatewayId(gate.gatewayID);
              props.handleOpen();
            }}
          >
            <img
              src={editIcon}
              alt="edit"
              className="inline-block h-[19px] ml-[10px] mb-1"
            ></img>
          </button>
        </TableCell>
        <TableCell align="left" sx={{ color: "#545454", fontSize: "1rem" }}>
          {gate.gatewayID}
        </TableCell>
        <TableCell align="left">
          <div className="text-[#545454] w-[100px]">
            <span>
              <img
                src={active ? Active : Inactive}
                className="inline-block h-[15px] mr-[5px]"
                alt="activeInactiveIcon"
              ></img>
            </span>{" "}
            {active ? "Active" : "Inactive"}
          </div>
        </TableCell>
        <TableCell align="center">
          {isNaN(epi) ? (
            <Link to={"/profile"}>
              <span className="text-[#EFC65E] underline">Update details</span>
            </Link>
          ) : (
            <span style={{ color: "green" }}>{epi}</span>
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{ borderBottomRightRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <CustomBtn
            sx={{ fontSize: "0.6rem" }}
            component={Link}
            to={`/device/${gate.gatewayID}`}
          >
            View Nodes
          </CustomBtn>
        </TableCell>
      </CusRow>
    </React.Fragment>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function FeviceInfo() {
  let api = createInstance();
  const styleOfHeader = {
    color: "#818181",
    fontSize: "0.8rem",
    fontWeight: "light",
  };
  let gateWayData = useSelector((state) => state.gateWayData);

  let filteredData = [];
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [gatewayName, setGatewayname] = useState("");
  const [ascName, setAscName] = useState(true);
  const [ascEPI, setEpi] = useState(true);
  const [gateWayId, setGatewayId] = useState("");

  const handleSortName = () => {
    let data = gateWayData;

    data = data.sort((a, b) => {
      let fa = a.name.toLowerCase();
      let fb = b.name.toLowerCase();

      if (ascName === false) {
        return fa.localeCompare(fb);
      } else {
        return fb.localeCompare(fa);
      }
    });
    if (ascName === true) {
      setAscName(false);
    } else {
      setAscName(true);
    }
    setGateWayData([...data]);
  };

  const handleClose = () => {
    setGatewayname("");
    setGatewayId("");
    setModalOpen(false);
  };
  const handleOpen = () => {
    setModalOpen(true);
  };
  const dispatch = useDispatch();

  const changeGatewayName = async (newGatewayName) => {
    const res = await api.post("/updategatewayname", {
      gatewayID: gateWayId,
      gatewayname: newGatewayName,
    });
    if (res.status === 200) {
      let data = [...gateWayData];
      data = data.map((dat) => {
        if (dat.gatewayID === gateWayId) {
          // console.log(dat);
          dat.name = newGatewayName;
        }
        return dat;
      });
      //make names chote se bada
      data = data.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa > fb) {
          return ascName ? 1 : -1;
        }
        if (fb > fa) {
          return ascName ? -1 : 1;
        }
        return 0;
      });
      dispatch(setGateWayData([...data]));
      setModalOpen(false);
    }
  };
  let handleSortEPI = () => {
    let data = gateWayData;

    data = data.sort((a, b) => {
      let epiA = (
        a.calculator_parameters.monthyconsumption /
        (a.calculator_parameters.air_conditioned_area * 0.09290303997) /
        (a.calculator_parameters.requirebenchmark / 12)
      ).toFixed(2);

      let epiB = (
        b.calculator_parameters.monthyconsumption /
        (b.calculator_parameters.air_conditioned_area * 0.09290303997) /
        (b.calculator_parameters.requirebenchmark / 12)
      ).toFixed(2);

      if (isNaN(epiA) && isNaN(epiB)) return 0; // If both are NaN, leave them unchanged
      if (isNaN(epiA)) return 1; // Move NaN to the end
      if (isNaN(epiB)) return -1; // Keep NaN in its current position
      if (ascEPI === false) {
        return epiA - epiB;
      } else {
        return epiB - epiA;
      }
      // For non-NaN values, perform regular sorting
    });

    if (ascEPI === false) {
      setEpi(true);
    } else {
      setEpi(false);
    }
    //make names chote se bada
    setGateWayData([...data]);
  };
  const clientData = useSelector((state) => state.clientData);
  const [search, setSearch] = useState("");

  filteredData = gateWayData.filter((data) => {
    if (search === "") {
      return data;
    } else {
      return data.name.toLowerCase().includes(search.toLowerCase());
    }
  });

  return (
    <>
      <Box className="mt-8">
        <CusModal
          open={modalOpen}
          handleClose={handleClose}
          currentName={gatewayName}
          text={"CHANGE GATEWAY NAME"}
          changeName={changeGatewayName}
        ></CusModal>
        <div className="w-11/12 mx-auto flex justify-between items-center flex-wrap">
          <h1 className="text-[#545454] text-xl p-2 md:pl-0 text-center md:text-left whitespace-nowrap">
            Welcome, {clientData.username}!
          </h1>

          <SearchBar
            search={search}
            setSearch={setSearch}
            label={"Search by Gateway Name"}
          />
        </div>
        <Divider className="h-8" orientation="horizontal" />
        <div className="w-11/12 mx-auto">
          <Box className="overflow-x-auto">
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: "#EDEDED" }}
              >
                <Table
                  aria-label="collapsible table"
                  sx={{ borderCollapse: "separate", borderSpacing: "0 0.5em" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={styleOfHeader}
                        className="whitespace-nowrap"
                      >
                        GATEWAY NAME{" "}
                        <button onClick={handleSortName}>
                          {ascName ? (
                            <SyncAltOutlined
                              sx={{
                                fontSize: "18px",
                                ml: "5px",
                                mb: "2px",
                                rotate: "90deg",
                              }}
                            ></SyncAltOutlined>
                          ) : (
                            <SyncAltOutlined
                              sx={{
                                fontSize: "18px",
                                ml: "5px",
                                mb: "2px",
                                rotate: "90deg",
                              }}
                            ></SyncAltOutlined>
                          )}
                        </button>
                      </TableCell>
                      <TableCell align="left" sx={styleOfHeader}>
                        GATEWAY ID
                      </TableCell>
                      <TableCell align="left" sx={styleOfHeader}>
                        STATUS
                      </TableCell>

                      <TableCell
                        align="center"
                        className="whitespace-nowrap"
                        sx={styleOfHeader}
                      >
                        <button onClick={handleSortEPI}>
                          {ascEPI ? (
                            <SyncAltOutlined
                              sx={{
                                fontSize: "18px",
                                ml: "5px",
                                mb: "2px",
                                rotate: "90deg",
                              }}
                            ></SyncAltOutlined>
                          ) : (
                            <SyncAltOutlined
                              sx={{
                                fontSize: "18px",
                                ml: "5px",
                                mb: "2px",
                                rotate: "90deg",
                              }}
                            ></SyncAltOutlined>
                          )}
                        </button>{" "}
                        EPI{" "}
                        <HtmlTooltip
                          title={
                            <>
                              Metric used for benchmarking energy usage in
                              commercial building.{" "}
                              <a
                                href="https://medium.com/@zodhyatech/what-is-epi-5a2c3db1e7aa"
                                className="underline text-blue-500 cursor-pointer"
                              >
                                Read more
                              </a>
                            </>
                          }
                        >
                          <Info
                            fontSize="smaller"
                            sx={{
                              fontSize: "14px",
                              color: "#C5C5C5",
                              mb: "2px",
                            }}
                          ></Info>
                        </HtmlTooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={styleOfHeader}
                        className="w-10"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((gate) => (
                      <Row
                        key={gate.gatewayID + "home"}
                        gate={gate}
                        handleOpen={handleOpen}
                        setGatewayname={setGatewayname}
                        searchString={search}
                        setGatewayId={setGatewayId}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
}
