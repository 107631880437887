export const setGateWayData = (data) => {
  return { payload: data, type: "SET_GATEWAY_DATA" };
};

export const deleteGateWayData = () => {
  return { type: "DELETE_GATEWAY_DATA" };
};
export const setClientData = (data) => {
  return { type: "SET_CLIENT_DATA", payload: data };
};

export const deleteClientData = () => {
  return { type: "REMOVE_CLIENT_DATA" };
};
