import React, { useState, useContext } from "react";
import Login from "../components/Login";
import Signup from "../components/Signup";

const LoginSignupPage = () => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <>
      {isLogin ? (
        <Login setIsLogin={setIsLogin}></Login>
      ) : (
        <Signup setIsLogin={setIsLogin}></Signup>
      )}
    </>
  );
};

export default LoginSignupPage;
