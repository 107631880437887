import React, { useEffect, useRef, useState } from "react";
import { AccountContext } from "./Account";
import { useContext } from "react";
import { Button, TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import createInstance from "../api";
import { useDispatch, useSelector } from "react-redux";
import {} from "amazon-cognito-identity-js";
import Timmer from "./Timmer";

const ChangeEmail = (props) => {
  const { handleClose, showSnackBar, setErrorMessage, setSeverity } = props;

  let api = createInstance();

  const [email, setEmail] = useState("");

  const [otp, setOtp] = useState("");

  const [otpSent, setOtpSent] = useState(false);

  const [sec, setSec] = useState(0);

  const { getSession } = useContext(AccountContext);

  // const interval = useRef();

  let clientData = useSelector((state) => state.clientData);
  const dispatch = useDispatch();

  // let updateTimmer = () => setTimmer((prev) => prev - 1);

  // // const startTimer = () => {
  // //   setTimmer(60);
  // //   clearInterval(interval.current);
  // //   interval.current = setInterval(updateTimmer, 1000);
  // // };

  // if (timmer === 0) {
  //   clearInterval(interval.current);
  // }

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const changeHandler = (e) => {
    let name = e.target.name;
    let val = e.target.value;

    switch (name) {
      case "newEmail":
        setEmail(val);
        break;
      default:
        console.log("none");
        break;
    }
  };

  const sendOtp = (e) => {
    e.preventDefault();
    const newAttributes = [
      {
        Name: "email",
        Value: email,
      },
    ];

    getSession().then(({ user }) => {
      user.updateAttributes(newAttributes, (err, res) => {
        if (err) {
          setErrorMessage(err.message);
          setSeverity("error");
          showSnackBar();
        } else {
          console.log("sent");
          setSeverity("success");
          setErrorMessage(`OTP sent to ${email}`);
          showSnackBar();
          setSec(60);
          setOtpSent(true);
        }
      });
    });
  };

  const validateOtp = (e) => {
    e.preventDefault();
    console.log(otp);

    getSession().then(({ user }) => {
      user.verifyAttribute("email", otp, {
        onSuccess: () => {
          api
            .put("/updateemail", {
              email: email,
              OrganizationID: clientData.OrganizationID,
            })
            .then((res) => {
              if (res.data.statusCode === 200) {
                setErrorMessage("Email updated");
                setSeverity("success");
                showSnackBar();
                handleClose();
                let cl = { ...clientData, Email: email };
                dispatch({ type: "SET_CLIENT_DATA", payload: cl });
              }
            });
          return;
        },
        onFailure: (err) => {
          console.log("wrong otp");
          setErrorMessage("OTP is incorrect");
          setSeverity("error");
          showSnackBar();
          return;
        },
      });
    });
  };

  return (
    <>
      {!otpSent ? (
        <form
          onSubmit={sendOtp}
          className="flex justify-start items-start  flex-col gap-3 w-fit"
        >
          <h1 className="text-[#545454] underline underline-offset-2">
            Change Email
          </h1>
          <TextField
            label="Enter new email"
            variant="outlined"
            size="small"
            type="email"
            name="newEmail"
            value={email}
            onChange={changeHandler}
            required={true}
          ></TextField>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      ) : (
        <form className="flex flex-col gap-3" onSubmit={validateOtp}>
          <h1 className="text-[#545454] underline underline-offset-2">
            Enter otp
          </h1>
          <div className="">
            <MuiOtpInput
              length={6}
              value={otp}
              gap={1}
              TextFieldsProps={{
                size: "small",
                placeholder: "-",
                sx: { width: "40px" },
                required: true,
              }}
              onChange={handleOtpChange}
            />
          </div>
          <div className="flex gap-2 justify-between">
            <Button variant="contained" type="submit" size="small">
              submit
            </Button>
            {sec !== 0 ? (
              <span className="text-sm text-[#545454]">
                resend after <Timmer sec={sec} setSec={setSec} /> sec
              </span>
            ) : (
              <span
                onClick={sendOtp}
                className="text-sm text-blue-500 underline cursor-pointer"
              >
                resend
              </span>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default ChangeEmail;
