import { Paper } from "@mui/material";
import React from "react";

const Card = (props) => {
  const Img = props.img;
  const data = props.data;
  return (
    <div className="flex items-center justify-center p-1">
      <img src={Img} alt="icon" className={`inline-block ${props.class}`}></img>
      <div className={`inline-block ${props.wordDiv}`}>
        <div
          className="pt-1 text-2xl text-left"
          style={{ color: props.isSaver ? "white" : "#545454" }}
        >
          {data.value}
          <span
            className="ml-2 text-xs"
            style={{ color: props.isSaver ? "white" : "#545454" }}
          >
            {data.unit}
          </span>
        </div>
        <div
          className=" ml-1 text-xs text-left"
          style={{ color: props.isSaver ? "white" : "#818181" }}
        >
          {data.text}
        </div>
      </div>
    </div>
  );
};

export default Card;
