import axios from "axios";

const createInstance = () => {
  // https://api.zodhyatech.com

  let jwt = JSON.parse(localStorage.getItem("jwtToken"));
  return axios.create({
    // baseURL: "https://api.zodhyatech.com/prod/v2",
    baseURL: "https://api.zodhyatech.com/dev/v2",

    headers: {
      Authorization: `${jwt}`,
    },
  });
};

export default createInstance;
