import React from "react";
import { Paper, IconButton, InputBase } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
const SearchBar = (props) => {
  const { search, setSearch, label } = props;
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        height: "50px",
      }}
      className="lg:w-96 w-full"
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={label}
        inputProps={{ "aria-label": "search google maps" }}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
