import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import createInstance from "../api";

const Bill = () => {
  let api = createInstance();
  const invoice = useSelector((state) => state.invoice);
  let paymentSatus = invoice?.paymentstatus ?? null;
  let dueDate = invoice?.due ?? null;

  const doPayment = async () => {
    const res = await api.post("/payment", { custid: invoice.custid });
    let url = res.data;
    window.location.href = url;
  };

  return (
    <div className="w-11/12 mx-auto">
      {" "}
      <div>
        <div className="flex justify-between flex-wrap  mt-10">
          <h1 className="text-4xl   text-[#545454]">Bills</h1>
          <Link
            to="/refund-policy"
            className="underline text-blue-600 cursor-pointer"
          >
            Refund policy
          </Link>
        </div>

        <Divider color="#818181" style={{ marginTop: "10px" }} />
      </div>
      <div>
        <p className="text-left  text-sm  mt-4 ml-3 text-[#818181]">
          CURRENT BILL
        </p>
        <div className="bg-white rounded-lg  p-2 min-h-[100px] mt-2 ">
          {paymentSatus == null ? (
            <div className="flex justify-center items-center min-h-[100px]">
              No bill generated
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-2 ">
                <span>customer id : {invoice.custid}</span>
                <span>username : {invoice.username}</span>
                <span>email : {invoice.email}</span>
                <span>
                  bill date : {invoice.from} to {invoice.to}
                </span>
                <span>
                  payment status : {paymentSatus ? "paid" : "not paid"}
                </span>
                <span>due date : {invoice.due}</span>
              </div>
              <div className="mt-3 ">
                <Divider color="#818181" />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                {" "}
                <span>Total : ₹{invoice.amount + invoice.discount} </span>
                <span>discount : ₹{invoice.discount}</span>
                <span>amount : ₹{invoice.amount}</span>
                <div>
                  <Button
                    variant="contained"
                    onClick={doPayment}
                    disabled={paymentSatus}
                  >
                    {paymentSatus ? <>Paid</> : <>Pay ₹{invoice.amount}</>}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bill;
