import {
  Grid,
  CircularProgress,
  Divider,
  IconButton,
  Button,
  Popover,
  Typography,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Box, Tab, Tabs, styled } from "@mui/material";
import Card from "../components/Card";
import CurrentGraph from "../components/Graphs/CurrentGraph";
import { Help, Info } from "@mui/icons-material";
import PFhigh from "../asset/PFhigh.png";
import PFmid from "../asset/PFmid.png";
import PFlow from "../asset/PFlow.png";
import PF0 from "../asset/PF0.png";
import PF1 from "../asset/PF1.png";
import EmissionP from "../asset/Emissions Produced.png";
import PowerC from "../asset/Power Consumed.png";
import Co2 from "../asset/Co2.png";
import Save from "../asset/Save.png";
import SaveMoney from "../asset/SaveMoney.png";
import VoltageGraph from "../components/Graphs/VoltageGraph";
import EnergyGraph from "../components/Graphs/EnergyGraph";
import { convertDate, convertTimeStampToDate } from "../helperFunc/convertDate";
import Loader from "../components/Loader";
import EditIcon from "../asset/edit.png";

import CusModal from "../components/CusModal";
import { setGateWayData } from "../redux/actions";
import createInstance from "../api";

const LoadInput = ({ loadLimitVal, setLoadLimitVal }) => {
  return (
    <div className="bg-[#545454] p-3 text-white rounded-md w-[175px] text-sm flex gap-2 items-center">
      <ThemeProvider theme={darkTheme}>
        <TextField
          value={loadLimitVal}
          onChange={(e) => setLoadLimitVal(e.target.value)}
          variant="outlined"
          label="Enter load limit"
          sx={{
            "& .MuiFormLabel-root": {
              fontSize: "1rem",
            },
          }}
          size="small"
        />
        <span>KW</span>
      </ThemeProvider>
    </div>
  );
};

const CustomTab = styled(Tab)(({ theme }) => ({
  fontSize: "11px",
  borderRadius: "12px 12px 0 0",
  minHeight: "30px",
  color: "#818181",
  "&.Mui-selected": {
    backgroundColor: "white",
    color: "#818181",
  },
}));

let memo = {};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const LiveData = (props) => {
  let api = createInstance();

  let { gateWayId } = useParams();
  let [loading, setLoading] = useState(true);
  let [loadingGraph, setLoadingGraph] = useState(false);
  let [showTime, setShowTime] = useState(false);
  let [presentData, setPresentData] = useState([]);
  let [loadLimit, setLoadLimit] = useState(0);
  const [select, setSelect] = React.useState("Energy");
  const [graphData, setGraphData] = useState([]);
  const [openLoadEdit, setOpenLoadEdit] = useState(false);

  const [graphType, setGraphType] = useState("Present month");
  const handleChangeGraphType = (event, newValue) => {
    setGraphType(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const gateWayDataAll = useSelector((state) => {
    return state.gateWayData;
  });

  const gateWayData = useSelector((state) =>
    state.gateWayData.filter((data) => {
      return data.gatewayID === gateWayId;
    })
  );

  console.log(gateWayData);

  let [nodeSelect, setNodeSelect] = useState(() => {
    let sele = "";
    Object.keys(gateWayData[0]).map((device, i) => {
      if (
        device !== "noofnode" &&
        device.toLowerCase().includes("node") &&
        gateWayData[0][device].mac !== "0:0:0:0:0:0"
      ) {
        if (sele === "") {
          sele = device;
        }
      }
    });
    return sele;
  });

  const getGraphData = async () => {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    let body = {};

    if (graphType === "Present month") {
      let startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

      let diff = (now - startOfMonth) / 3600000;

      let start = convertDate(startOfMonth);
      let end = convertDate(now);

      if (diff <= 1) {
        // day wise data
        setShowTime(true);
        body = {
          starthour: start,
          endhour: end,
          slug: "hour",
        };
      } else if (diff > 1 && diff <= 48) {
        setShowTime(true);
        body = {
          starthour: start,
          endhour: end,
          slug: "day",
        };
      } else {
        setShowTime(false);
        body = {
          starthour: start,
          endhour: end,
          slug: "month",
        };
      }
    } else if (graphType === "Previous month") {
      let startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);

      let endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59);

      let start = convertDate(startDate);
      let end = convertDate(endDate);
      setShowTime(false);
      body = {
        starthour: start,
        endhour: end,
        slug: "month",
      };
    }

    body = { GatewayID: gateWayId, ...body };

    let key = JSON.stringify(body);

    if (key in memo) {
      setGraphData(memo[key]);
      return;
    }

    let res = await api.post("/rangedata", { GatewayID: gateWayId, ...body });
    if (res.data.statuscode) {
      // setShowMessage(true);
      memo[key] = [];
    } else {
      res.data.reverse();
      memo[key] = res.data;
      setGraphData(res.data);
    }
  };

  let [liveData, setLiveData] = useState({
    unitConsumed: {
      value: 0,
      unit: "kWh",
      text: "Units Consumed",
    },
    emissionProduced: {
      value: 0,
      unit: "kg CO₂",
      text: "Emission Caused",
    },
    pf: {
      value: 0,
      unit: " ",
      text: "Power Factor",
    },
    load: {
      value: 0,
      unit: "kW",
      text: "Load",
    },
  });
  let [saverData, setSaverData] = useState({
    unitSaved: {
      value: 0.0,
      image: "",
      unit: "kWh",
      text: "Units Saved",
    },
    emissionSaved: {
      value: 0.0,
      image: "",
      unit: "kg CO2",
      text: "Emissions Reduced",
    },
    inrSaved: {
      value: 0.0,
      image: "",
      unit: "",
      text: "INR Saved",
    },
  });

  useEffect(() => {
    const func = async () => {
      await getGraphData();
    };
    func();
  }, [graphType]);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let [res] = await Promise.all([
        api.post("/presentdata", { GatewayID: gateWayId }),
      ]);

      if (res.data == null) {
        setLoading(false);
        return;
      }

      setPresentData(res.data);

      let data = res.data[0][nodeSelect];

      data ??= {};
      setLiveData({
        ...liveData,
        load: { ...liveData.load, value: data.Mean_Pact ?? 0 },
        pf: { ...liveData.pf, value: data.Mean_PF ?? 0 },
        unitConsumed: {
          ...liveData.unitConsumed,
          value: data.Mean_Eact ?? 0,
        },
        emissionProduced: {
          ...liveData.emissionProduced,
          value: ((data.Mean_Eact ?? 0) * 0.71).toFixed(2),
        },
      });

      setLoading(false);
    };

    getData();
  }, []);

  const getNewData = async () => {
    let res = await api.post("/mostrecentdata", { GatewayID: gateWayId });

    if (res.data == null) {
      return;
    }

    let data = res.data[0];

    if (data.Timestamp !== presentData[0].Timestamp) {
      setPresentData((prev) => {
        return [data, ...prev];
      });
    }
  };

  useEffect(() => {
    let setTimmer = setInterval(() => {
      getNewData();
    }, 60000);

    return () => {
      clearInterval(setTimmer);
    };
  }, [presentData]);

  useEffect(() => {
    setLoadLimit(
      gateWayData[0]?.calculator_parameters[`${nodeSelect}load`] && 0
    );
    setLoadLimitVal(
      gateWayData[0]?.calculator_parameters[`${nodeSelect}load`] && 0
    );
    if (presentData.length !== 0) {
      let data = presentData[0][nodeSelect];

      data ??= {};

      setLiveData({
        ...liveData,
        load: { ...liveData.load, value: data.Mean_Pact ?? 0 },
        pf: { ...liveData.pf, value: data.Mean_PF ?? 0 },
        unitConsumed: {
          ...liveData.unitConsumed,
          value: data.Mean_Eact ?? 0,
        },
        emissionProduced: {
          ...liveData.emissionProduced,
          value: ((data.Mean_Eact ?? 0) * 0.71).toFixed(2),
        },
      });
    }
  }, [presentData, nodeSelect]);

  // let PF = PFlow;

  const handleChange = (event, newValue) => {
    setNodeSelect(newValue);
  };

  const renderGraph = () => {
    switch (select) {
      case "Voltage":
        //voltage and current graph height 20px less because of custom legends

        return (
          <VoltageGraph
            data={graphData}
            GatewayID={gateWayId}
            graphHeight="180px"
            showTime={showTime}
            nodeSelect={nodeSelect}
          ></VoltageGraph>
        );
      //graph
      case "Current":
        return (
          <CurrentGraph
            data={graphData}
            GatewayID={gateWayId}
            graphHeight="180px"
            nodeSelect={nodeSelect}
            showTime={showTime}
          />
        );
      case "Energy":
        return (
          <EnergyGraph
            data={graphData}
            GatewayID={gateWayId}
            graphHeight="200px"
            showTime={showTime}
            nodeSelect={nodeSelect}
          ></EnergyGraph>
        );
      default:
        return <h1>selectGraph</h1>;
    }
  };
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => {
    setNodeName("");
    setModalOpen(false);
  };
  const [nodeName, setNodeName] = useState("");

  const changeNodeName = async (newNodeName) => {
    const res = await api.put("/updatenodename", {
      gatewayID: gateWayId,
      nodename: newNodeName,
      node: nodeSelect,
    });
    if (res.status === 200) {
      let data = [...gateWayDataAll];
      // data[gateWayId][nodeSelect].name;
      data = data.map((dat) => {
        if (dat.gatewayID === gateWayId) {
          // console.log(dat);
          dat[nodeSelect].name = newNodeName;
        }
        return dat;
      });
      dispatch(setGateWayData([...data]));
      setModalOpen(false);
    }
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(nodeSelect);

  const giveSrc = () => {
    let val = liveData.pf.value;

    if (val === 1) {
      return PF1;
    } else if (val === 0) {
      return PF0;
    } else if (val > 0 && val < 0.8) {
      return PFlow;
    } else if (val >= 0.8 && val < 0.9) {
      return PFmid;
    } else if (val >= 0.9 && val < 1) {
      return PFhigh;
    }
  };

  let percent = (liveData.load.value / loadLimit) * 100;

  let height = ((55 * percent) / 100).toFixed(2);

  //load icon className
  let loadIconClass = isFinite(percent)
    ? percent < 100
      ? `absolute bottom-0 left-0 flex justify-center items-center  w-9 bg-[#2FBCBC]`
      : `absolute bottom-0 left-0  flex  justify-center items-center  w-9 bg-[#EA5C54]`
    : `absolute bottom-0 left-0  flex justify-center items-center  w-9 bg-[#2FBCBC]`;

  const [isHovering, setIsHovering] = useState(false);
  const [loadLimitVal, setLoadLimitVal] = useState(loadLimit);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const gatewayData = useSelector((state) => state.gateWayData);

  const handleMouseOut = () => {
    // data.gatewayID === gateWayId;

    let newData = gateWayData.map((data) => {
      if (data.gatewayID === gateWayId) {
        data.calculator_parameters[`${nodeSelect}load`] = loadLimitVal;
      }
      return data;
    });

    setLoadLimit(loadLimitVal);

    setGateWayData([...newData]);

    setIsHovering(false);
    setOpenLoadEdit(false);
  };

  return loading ? (
    <Loader></Loader>
  ) : (
    <Box className="min-h-screen bg-white overflow-x-hidden static overflow-y-auto">
      <CusModal
        open={modalOpen}
        handleClose={handleClose}
        currentName={nodeName}
        text={"CHANGE NODE NAME"}
        changeName={changeNodeName}
      ></CusModal>

      <div className="flex justify-center items-center p-2 space-x-3 flex-nowrap mt-1 w-11/12 mx-auto">
        <p className=" text-[#818181] text-sm  lg:text-base  md:text-base  text-center">
          GATEWAY ID: {gateWayId}
        </p>
        <Divider orientation="vertical" flexItem />
        <p className="text-[#545454] text-sm  lg:text-base  md:text-base  text-center">
          {gateWayData[0].name}
        </p>
      </div>

      <Divider className="h-1"></Divider>
      <Box
        display="flex"
        justifyContent="center"
        width="80vw"
        sx={{ mx: "auto" }}
      >
        <Tabs
          value={nodeSelect}
          component={"div"}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          TabScrollButtonProps={{
            style: {
              width: "auto",
              "&.Mui-disabled": {
                opacity: 0.3,
              },
            }, // adjust the width here
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#818181",
            },
          }}
          // className="mt-2"
        >
          {Object.keys(gateWayData[0]).map((device, i) => {
            if (
              device !== "noofnode" &&
              device.toLowerCase().includes("node") &&
              gateWayData[0][device].mac !== "0:0:0:0:0:0"
            ) {
              return (
                <Tab
                  label={
                    <span>
                      {device} : {gateWayData[0][device].name ?? device}
                      {nodeSelect === device && (
                        <IconButton
                          size="small"
                          component="span"
                          onClick={() => {
                            setModalOpen("true");
                            setNodeName(gateWayData[0][device].name);
                          }}
                        >
                          <img
                            src={EditIcon}
                            alt="edit"
                            className="h-5 mb-1 ml-1"
                          ></img>
                        </IconButton>
                      )}
                    </span>
                  }
                  value={device}
                  key={i}
                  sx={{
                    fontSize: "12px",
                    height: "20px",
                    color: "#818181",
                    "&.Mui-selected": {
                      backgroundColor: "#EDEDED",
                      color: "#818181",
                    },
                  }}
                ></Tab>
              );
            }
          })}
        </Tabs>
      </Box>
      <div className="h-6"></div>
      <Box className="bg-[#EDEDED] w-[90%] p-10 pt-0 rounded-lg mx-auto">
        <Grid container spacing={3} justify="center" align="center">
          <Grid item md={6} xs={12}>
            <Box>
              <h1 className="text-left text-sm mt-0 ml-3 mb-1 text-[#818181]">
                LIVE DATA{" "}
                <span className="text-xs whitespace-nowrap  text-[#a6a4a4] ml-2 ">
                  Last Updated :{" "}
                  {convertTimeStampToDate(
                    (presentData[0]?.Timestamp ?? "").toString()
                  ).toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </span>
              </h1>
              <div className=" rounded-lg bg-white shadow-md flex  h-[150px] pt-3 justify-evenly items-center ">
                <div>
                  <div className="border-b-[1px] border-t-[1px] border-[#818181] w-[70px] relative">
                    {loadLimit && (
                      <div className="text-[10px] absolute left-[72px] top-[-10px] text-[#818181] whitespace-nowrap">
                        {loadLimit} KW
                      </div>
                    )}
                    <div className=" w-[80%]">
                      <div className="w-9 h-[55px] bg-[#818181] relative">
                        <div
                          className={loadIconClass}
                          style={{
                            height: percent < 100 ? `${height}px` : "55px",
                          }}
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                        >
                          {!isFinite(percent) && (
                            <p className="text-xs leading-none ">
                              <Help sx={{ color: "#545454" }} />
                              {isHovering && (
                                <div className="absolute bottom-[-1] left-6 ">
                                  <LoadInput
                                    loadLimitVal={loadLimitVal}
                                    setLoadLimitVal={setLoadLimitVal}
                                  ></LoadInput>
                                </div>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="pt-2 text-2xl text-center"
                    style={{ color: "#545454" }}
                  >
                    {liveData.load.value}
                    <span className="ml-2 text-xs" style={{ color: "#545454" }}>
                      {liveData.load.unit}
                    </span>
                    <div className="text-center text-xs text-[#818181] flex justify-center items-center gap-2 ">
                      <span>{liveData.load.text}</span>
                      <div
                        className="relative"
                        onMouseOut={handleMouseOut}
                        onMouseOver={() => {
                          setOpenLoadEdit(true);
                        }}
                      >
                        <img
                          src={EditIcon}
                          alt="editIcon"
                          className="w-[15px] cursor-pointer"
                        ></img>
                        {openLoadEdit && (
                          <div className="absolute  left-[15px]  bottom-[-50px]">
                            <LoadInput
                              setLoadLimitVal={setLoadLimitVal}
                              loadLimitVal={loadLimitVal}
                            ></LoadInput>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>

                  <img src={giveSrc()} alt="epi" className="h-[55px]"></img>
                  <div
                    className="pt-2 text-2xl text-center"
                    style={{ color: "#545454" }}
                  >
                    {liveData.pf.value}
                    <span className="ml-2 text-xs" style={{ color: "#545454" }}>
                      {liveData.pf.unit}
                    </span>
                    <div className="text-center text-xs text-[#818181]">
                      <span>{liveData.pf.text} </span>
                    </div>
                  </div>
                
                </div>
                 
                 
                

              </div>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box>
              <h1 className="text-left text-sm  mt-0 ml-3 mb-1 text-[#818181]">
                THIS MONTH{" "}
              </h1>
              <div className=" rounded-lg bg-white shadow-md h-[150px] flex flex-col justify-evenly">
                <Card
                  img={PowerC}
                  data={liveData.unitConsumed}
                  class="h-[48px] mr-10 relative right-2"
                ></Card>
                <Card
                  img={EmissionP}
                  data={liveData.emissionProduced}
                  class="h-[38px] mr-10"
                ></Card>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Box className="mt-3 flex justify-between items-center flex-wrap gap-2">
          <h1 className="text-left  text-sm  mt-0 ml-3 text-[#818181]">
            ENERGY DATA TRENDS{" "}
            <Info sx={{ fontSize: "14px", color: "#C5C5C5", mb: "2px" }}></Info>
          </h1>
          <div className="md:justify-end flex justify-space-around flex-wrap-reverse gap-2">
            <div className="mr-[10px]">
              <Tabs
                value={graphType}
                onChange={handleChangeGraphType}
                sx={{ minHeight: "30px" }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#818181",
                  },
                }}
              >
                <CustomTab label="Previous month" value={"Previous month"} />
                <CustomTab label="Present month" value={"Present month"} />
              </Tabs>
            </div>
            {/* <Selector
              className="mr-2"
              values={["Energy", "Current", "Voltage"]}
              names={["Energy", "Current", "Voltage"]}
              select={select}
              name="Graph"
              handleChange={handleChangeSelect}
            ></Selector> */}
          </div>
        </Box>
        <Box className=" bg-white rounded-lg p-2 ">
          {loadingGraph ? (
            <div className="text-center">
              <CircularProgress sx={{ color: "#F97316" }} />
            </div>
          ) : (
            <Box className="bg-white h-[200px] relative">
              <Button
                variant="contained"
                sx={{
                  position: "absolute",
                  bottom: "0",
                  right: "5px",
                  fontSize: { md: "12px", xs: "9px" },
                  width: { md: "80px", xs: "50px" },
                  textAlign: "center",
                  padding: "1px",
                  zIndex: 3,
                  backgroundColor: "#F97316",
                  ":hover": { backgroundColor: "#F97316" },
                }}
                component={Link}
                to="/analytics"
                state={{ gateWayId: gateWayId, nodeId: nodeSelect }}
              >
                Analytics
              </Button>
              {renderGraph()}
            </Box>
          )}
        </Box>

        {/* <Box>
          <h1 className="text-left text-sm  ml-3 mt-3 mb-1 text-[#818181]">
            SAVER DATA{" "}
            <Info sx={{ fontSize: "14px", color: "#C5C5C5", mb: "2px" }}></Info>
          </h1>
          <Box className=" rounded-lg shadow-md bg-[#298F9D]">
            <Box className="flex flex-wrap  justify-evenly items-center ">
              <div className=" flex w-full md:w-1/3 justify-start md:justify-center ml-[30%] md:ml-0 ">
                <Card
                  img={SaveMoney}
                  isSaver={true}
                  class="h-[53px] md:mr-2 sm:mr-3"
                  // wordDiv="sm:mr-11"
                  data={saverData.inrSaved}
                ></Card>
              </div>
              <div className=" flex w-full md:w-1/3 justify-start md:justify-center ml-[30%] md:ml-0">
                <Card
                  img={Save}
                  isSaver={true}
                  class="h-[49px] md:mr-2 sm:mr-3"
                  // wordDiv="sm:mr-10"
                  data={saverData.unitSaved}
                ></Card>
              </div>
              <div className=" flex w-full md:w-1/3 justify-start md:justify-center ml-[30%] md:ml-0">
                <Card
                  img={Co2}
                  isSaver={true}
                  class="h-[50px] md:mr-2 sm:mr-3"
                  data={saverData.emissionSaved}
                ></Card>
              </div>
            </Box>
          </Box>
        </Box> */}
        <div className="h-5"></div>
      </Box>
    </Box>
  );
};

export default LiveData;
