import React, { useState } from "react";
import {
    Modal,
    Box,
    Divider,
    Button,
    TextField,
} from "@mui/material";

import { Cancel, Add } from "@mui/icons-material";
import { LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { useDispatch, useSelector } from "react-redux";
import createInstance from "../api";
import Notification from "./Notification";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    border: "0",
};

const textStyle = "my-2 text-[#545454] font-bold";

const inputStyle = {};

const PresetsModal = (props) => {
    let api = createInstance();
    let clientData = useSelector((state) => state.clientData);
    const dispatch = useDispatch();
    const [openNoti, setOpenNoti] = useState(false);
  


    const [presets, setPresets] = useState({
        shift1: {
            name: clientData.Presets?.shift1?.name || "",
            startTime: clientData.Presets?.shift1?.startTime || "",
            endTime: clientData.Presets?.shift1?.endTime || "",
        },
        shift2: {
            name: clientData.Presets?.shift2?.name || "",
            startTime: clientData.Presets?.shift2?.startTime || "",
            endTime: clientData.Presets?.shift2?.endTime || "",
        },
        shift3: {
            name: clientData.Presets?.shift3?.name || "",
            startTime: clientData.Presets?.shift3?.startTime || "",
            endTime: clientData.Presets?.shift3?.endTime || "",
        },
    });
    
    const showSnackBar = () => {
        setOpenNoti(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenNoti(false);
    };

    const handlePresetChange = (shift, field, value) => {
        const newPresets = { ...presets };
        newPresets[shift][field] = dayjs(value).format("HH:mm"); // Convert to HH:mm format
        setPresets(newPresets);
    };

    const handleNameChange = (shift, value) => {
        const newPresets = { ...presets };
        newPresets[shift].name = value;
        setPresets(newPresets);
    };



    const submitHandler = async (e) => {
        e.preventDefault();

        try {
            await api.post("/presets", {
                OrganizationID: clientData.OrganizationID,
                presets,
            });

            props.handleClose();
            showSnackBar();
            let cl = { ...clientData, Presets: presets };
            dispatch({ type: "SET_CLIENT_DATA", payload: cl });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Notification
                open={openNoti}
                position={{ vertical: "top", horizontal: "center" }}
                handleClose={handleClose}
                errorMessage={"Data updated"}
                severity="success"
            ></Notification>


            <Modal open={props.open} onClose={props.handleClose}>
                <Box
                    sx={style}
                    className="mx-auto h-[80vh] md:w-[50%] w-[55%] overflow-y-auto"
                >
                    <button
                        onClick={props.handleClose}
                        className="absolute right-5 top-3"
                    >
                        <Cancel sx={{ color: "red" }}></Cancel>
                    </button>
                    <Box className="w-[55%] md:w-[50%] mx-auto">
                        <h1 className="text-xl text-[#545454]">Enter your Shifts</h1>
                        <Divider></Divider>
                        <div className="flex gap-3 justify-evenly flex-wrap">
                            <Box className="mt-7 space-y-5">
                                <form onSubmit={submitHandler}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {Object.keys(presets).map((shift, index) => (
                                            <div key={shift}>
                                                <h3 className={textStyle}>
                                                    {`${shift}`}
                                                </h3>
                                                <TextField
                                                    label="Shift Name"
                                                    variant="outlined"
                                                    value={presets[shift].name}
                                                    onChange={(e) =>
                                                        handleNameChange(shift, e.target.value)
                                                    }
                                                    sx={inputStyle}
                                                    className="w-[100%]"
                                                />
                                                <div className="flex gap-3 my-2">
                                                    <TimePicker
                                                        label="Start Time"
                                                        value={presets[shift].startTime ? dayjs(presets[shift].startTime, "HH:mm") : null}
                                                        onChange={(newValue) =>
                                                            handlePresetChange(shift, "startTime", newValue)
                                                        }
                                                        viewRenderers={{
                                                            hours: renderTimeViewClock,
                                                            minutes: renderTimeViewClock,
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField {...params} sx={inputStyle} className="w-[100%]" />
                                                        )}
                                                    />
                                                    <TimePicker
                                                        label="End Time"
                                                        value={presets[shift].endTime ? dayjs(presets[shift].endTime, "HH:mm") : null}
                                                        onChange={(newValue) =>
                                                            handlePresetChange(shift, "endTime", newValue)
                                                        }
                                                        viewRenderers={{
                                                            hours: renderTimeViewClock,
                                                            minutes: renderTimeViewClock,
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField {...params} sx={inputStyle} className="w-[100%]" />
                                                        )}
                                                    />
                                                </div>

                                            </div>
                                        ))}
                                    </LocalizationProvider>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            display: "block",
                                            backgroundColor: "#F97316",
                                            mt: "10px",
                                            ":hover": { backgroundColor: "#F97316" },
                                        }}
                                    >
                                        Update
                                    </Button>
                                </form>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default PresetsModal;
