import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Input,
  TextField,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  border: "0",
};
const CusModal = (props) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setName(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await props.changeName(name);
    setLoading(false);
    setName("");
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="md:w-fit w-11/12 flex justify-center">
        <form
          onSubmit={submitHandler}
          className="flex justify-center items-center  flex-col gap-3 w-fit flex-nowrap"
        >
          <h1 className="mb-2 text-lg text-left text-[#545454]">
            {props.text}
          </h1>
          <TextField
            value={props.currentName}
            disabled
            label="CURRENT NAME"
            required={true}
            size="small"
          ></TextField>
          <TextField
            label="Enter new name"
            size="small"
            value={name}
            required={true}
            onChange={handleChange}
          ></TextField>
          <div className="flex gap-2">
            <LoadingButton
              variant="contained"
              loading={loading}
              sx={{
                backgroundColor: "#F97316",

                ":hover": { backgroundColor: "#F97316" },
              }}
              type="submit"
            >
              SUBMIT
            </LoadingButton>
            <Button
              variant="outlined"
              color="error"
              disabled={loading}
              onClick={() => {
                setName("");
                props.handleClose();
              }}
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default CusModal;
