import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  ListItemButton,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
  Button,
  Divider,
  ListItemText,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

// import Ana from "../asset/Analytics.png";
// import AnaActive from "../asset/AnalyticsActive.png";
// import Home from "../asset/Home.png";
// import HomeActive from "../asset/HomeActive.png";
// import Profile from "../asset/Profile.png";
// import ProfileActive from "../asset/ProfileActive.png";
import Logout from "../asset/logout.png";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import LogoutIcon from "@mui/icons-material/Logout";
import {
  Home,
  Assessment,
  AccountCircle,
  Summarize,
  CurrencyRupee,
  RequestQuote,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteClientData, deleteGateWayData } from "../redux/actions/index";
import logo from "../asset/icon.png";
import Zodhya from "../asset/zodhya.png";
// import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";

const Navbar = (props) => {
  const { window, mobileOpen, handleDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  let dispatch = useDispatch();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawerWidth = 285;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { pathname } = useLocation();

  let navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
    dispatch(deleteClientData());
    dispatch(deleteGateWayData());
    setDialogOpen(false);
  };

  const drawer = (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
        className="mt-9 mb-12  "
      >
        <Link to={"/"} onClick={handleDrawerToggle}>
          <img
            src={logo}
            alt="zodhya-logo"
            className="h-7  inline-block mb-1 "
          ></img>
          <img src={Zodhya} alt="ioioi" width={90} className="mx-auto" />
        </Link>
      </Box>
      <Box className="flex flex-col justify-between h-[100vh]">
        <List style={{ justifyContent: "center" }}>
          {[
            {
              name: "Gateways",
              icon: <Home sx={{ color: "#C5C5C5" }} />,
              iconActive: <Home sx={{ color: "#298F9D" }} />,
              to: "/",
            },
            {
              name: "Analytics",
              icon: <Assessment sx={{ color: "#C5C5C5" }} />,
              iconActive: <Assessment sx={{ color: "#298F9D" }} />,
              to: "/analytics",
            },
            {
              name: "Reports",
              icon: <Summarize sx={{ color: "#C5C5C5" }} />,
              iconActive: <Summarize sx={{ color: "#298F9D" }} />,
              to: "/reports",
            },
            {
              name: "Bills",
              icon: <RequestQuote sx={{ color: "#C5C5C5" }} />,
              iconActive: <RequestQuote sx={{ color: "#298F9D" }} />,
              to: "/bill",
            },
          ].map((text, index) => (
            <ListItem
              key={text.name}
              disablePadding
              sx={{
                backgroundColor: text.to === pathname ? "#EDEDED" : "",
              }}
            >
              <ListItemButton
                component={Link}
                to={text.to}
                onClick={handleDrawerToggle}
              >
                <Box
                  className="flex items-center justify-center"
                  style={{ color: "#818181" }}
                  sx={{ ml: { xs: 2, sm: 2, lg: 7 } }}
                >
                  <ListItemIcon>
                    {text.to === pathname ? text.iconActive : text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    sx={{ color: text.to === pathname ? "#298F9D" : "" }}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Box className="mb-12">
          <Divider />
          <List>
            {[
              {
                name: "Account",
                icon: <AccountCircle sx={{ color: "#C5C5C5" }} />,
                iconActive: <AccountCircle sx={{ color: "#298F9D" }} />,
                to: "/profile",
              },
            ].map((text, index) => (
              <ListItem
                onClick={handleDrawerToggle}
                key={text.name}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor: text.to === pathname ? "#EDEDED" : "",
                }}
              >
                <ListItemButton component={Link} to={text.to}>
                  <Box
                    className="flex items-center justify-center"
                    style={{
                      color: "#818181",
                    }}
                    sx={{ ml: { xs: 2, sm: 2, lg: 7 } }}
                  >
                    <ListItemIcon>
                      {" "}
                      {text.to === pathname ? text.iconActive : text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.name}
                      sx={{ color: text.to === pathname ? "#298F9D" : "" }}
                    />
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem
              key={"Logout"}
              disablePadding
              sx={{
                display: "block",
              }}
            >
              <ListItemButton
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                <Box
                  className="flex items-center justify-center"
                  style={{ color: "#818181" }}
                  sx={{ ml: { xs: 2, sm: 2, lg: 7 } }}
                >
                  <ListItemIcon>
                    <img
                      src={Logout}
                      alt="logout"
                      className="h-[20px] pl-[3px]"
                    ></img>
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{
            width: { md: "220px", lg: "285px", sm: "220px" },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: "220px", lg: "285px", sm: "220px", xs: "220px" },
              },
              position: "relative",
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: "220px", lg: "285px", sm: "220px", xs: "220px" },
              },
              position: "relative",
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <div>
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F97316",
                ":hover": { backgroundColor: "#F97316" },
              }}
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleLogout();
              }}
              autoFocus
            >
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Navbar;

// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { Home, Analytics } from "@mui/icons-material";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import { Icon } from "@mui/material";
// import { useState } from "react";
// import Zodhya from "../asset/zodhya.png";

// const drawerWidth = 300;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   height: "100vh",
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
//   height: "100vh",
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// // const AppBar = styled(MuiAppBar, {
// //   shouldForwardProp: (prop) => prop !== "open",
// // })(({ theme, open }) => ({
// //   zIndex: theme.zIndex.drawer + 1,
// //   transition: theme.transitions.create(["width", "margin"], {
// //     easing: theme.transitions.easing.sharp,
// //     duration: theme.transitions.duration.leavingScreen,
// //   }),
// //   ...(open && {
// //     marginLeft: drawerWidth,
// //     width: `calc(100% - ${drawerWidth}px)`,
// //     transition: theme.transitions.create(["width", "margin"], {
// //       easing: theme.transitions.easing.sharp,
// //       duration: theme.transitions.duration.enteringScreen,
// //     }),
// //   }),
// // }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// export default function Navbar() {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);

//   const handleDrawer = () => {
//     setOpen(!open);
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       {/* <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{
//               marginRight: 5,
//               ...(open && { display: "none" }),
//             }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             Mini variant drawer
//           </Typography>
//         </Toolbar>
//       </AppBar> */}
//       <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
//         <DrawerHeader>
//           {open && (
//             <ListItem>
//               <ListItemIcon sx={{ height: "30px" }}>
//                 <img src={logo} alt="sadas"></img>
//               </ListItemIcon>
//               <ListItemText>
//                 <img src={Zodhya} alt="aoiaiod" width="90px"></img>
//               </ListItemText>
//             </ListItem>
//           )}

//           {open ? (
//             <IconButton onClick={handleDrawer}>
//               <ChevronLeftIcon />
//             </IconButton>
//           ) : (
//             <button onClick={handleDrawer}>
//               <img src={logo} alt="sadas" width="50px"></img>
//             </button>
//           )}
//         </DrawerHeader>
//         <Divider />
// <List>
//   {[
//     { name: "home", icon: <Home></Home> },
//     { name: "Analytics", icon: <Analytics></Analytics> },
//   ].map((text, index) => (
//     <ListItem key={text} disablePadding sx={{ display: "block" }}>
//       <ListItemButton
//         sx={{
//           minHeight: 48,
//           justifyContent: open ? "initial" : "center",
//           px: 2.5,
//         }}
//       >
//         <ListItemIcon
//           sx={{
//             minWidth: 0,
//             mr: open ? 3 : "auto",
//             justifyContent: "center",
//           }}
//         >
//           {text.icon}
//         </ListItemIcon>
//         <ListItemText
//           primary={text.name}
//           sx={{ opacity: open ? 1 : 0 }}
//         />
//       </ListItemButton>
//     </ListItem>
//   ))}
// </List>
//         <Divider />
//         <List>
//           {[{ name: "home", icon: <Home></Home> }].map((text, index) => (
//             <ListItem key={text} disablePadding sx={{ display: "block" }}>
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {text.icon}
//                 </ListItemIcon>
//                 <ListItemText
//                   primary={text.name}
//                   sx={{ opacity: open ? 1 : 0 }}
//                 />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>
//     </Box>
//   );
// }
