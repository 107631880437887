import React from "react";
import { Checkbox } from "@mui/material";

const GraphLegends = (props) => {
  let { toggle } = props;
  return (
    <div className="flex absolute gap-1 md:gap-2  bottom-0 md:left-[50%] md:translate-x-[-50%] w-[140px] md:w-[100%] justify-center flex-wrap">
      <div className="md:text-sm whitespace-nowrap text-xs">
        <Checkbox
          value="Phase A"
          onChange={toggle}
          defaultChecked
          size="small"
          sx={{
            color: "#C5C5C5",
            "&.Mui-checked": {
              color: "#EA5C54",
            },
            p: 0,
          }}
        ></Checkbox>
        Phase A
      </div>
      <div className="md:text-sm whitespace-nowrap text-xs">
        <Checkbox
          value="Phase B"
          onChange={toggle}
          defaultChecked
          size="small"
          sx={{
            color: "#C5C5C5",
            "&.Mui-checked": {
              color: "#EFC65E",
            },
            p: 0,
          }}
        ></Checkbox>
        Phase B
      </div>
      <div className="md:text-sm whitespace-nowrap text-xs   ">
        <Checkbox
          value="Phase C"
          onChange={toggle}
          defaultChecked
          size="small"
          sx={{
            color: "#C5C5C5",
            "&.Mui-checked": {
              color: "#298F9D",
            },
            p: 0,
          }}
        ></Checkbox>
        Phase C
      </div>
    </div>
  );
};

export default GraphLegends;
