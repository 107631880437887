import React from "react";

const RefundPolicy = () => {
  return (
    <div className="flex gap-5 flex-col px-5 mt-16">
      <h1 className="text-3xl font-semibold underline">Refund policy</h1>
      <div>
        <h2 className="text-xl font-medium">What is the return policy?</h2>
        <p>
          Returns are accepted within 30 days of MOU/Contract. Personalized or
          custom products can't be returned. Products marked as non-returnable
          within the product's description cannot be returned.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">
          {" "}
          What if the wrong item is sent, or the product is defective?
        </h2>
        <p>
          If you get the wrong item or defective product, please contact
          Customer Service at{" "}
          <a
            href="mailto:contact@zodhyatech.com"
            className="underline text-blue-600 cursor-pointer"
          >
            contact@zodhyatech.com
          </a>{" "}
          for help with your order.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">
          Can personalized or custom products be returned?
        </h2>
        <p>
          All personalized or custom products are final sale and cannot be
          returned.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium"> How to track my return?</h2>
        <p>
          Once a return is processed, you will receive an email notification.
          For more information on a return that is in process, please contact
          Customer Service at{" "}
          <a
            href="mailto:contact@zodhyatech.com"
            className="underline text-blue-600 cursor-pointer"
          >
            contact@zodhyatech.com
          </a>
          .
        </p>
      </div>
      <div>
        <h2 className="text-xl font-medium">How long do refunds take?</h2>
        <p>
          Once a refund is processed, it may take 7-10 business days before
          showing up on your billing statement.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
