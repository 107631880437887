import { Download } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

const DownloadMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="absolute top-[-7px] right-[-5px]">
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Download sx={{ color: "#818181" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.downloadImg();
            handleClose();
          }}
          sx={{ fontSize: "12px" }}
        >
          PNG download
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.downloadCsv();
            handleClose();
          }}
          sx={{ fontSize: "12px" }}
        >
          CSV download
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DownloadMenu;
