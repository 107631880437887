import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Divider,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import createInstance from "../api";
import { setGateWayData } from "../redux/actions";
import Notification from "./Notification";
import weatherData from "../helperFunc/weatherData.json";
import epiData from "../helperFunc/epiData.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  border: "0",
};

const textStyle = "my-2 text-[#545454] font-bold ";

const inputStyle = {};

const GateWayDetailsModal = (props) => {
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const stateData = Object.keys(weatherData);
  const [districtData, setDistrictData] = useState([]);

  let api = createInstance();

  const gatewayData = useSelector((state) => {
    return state.gateWayData;
  });

  let gateway = null;

  gatewayData.forEach((gate) => {
    if (gate.gatewayID === props.gatewayID) {
      gateway = gate;
    }
  });

  const [acCapacity, setAcCapacity] = useState(0);

  const [opHours, setOpHours] = useState(0);

  const [area, setArea] = useState(0);
  const [openNoti, setOpenNoti] = useState(false);
  const [monthlyComp, setMonthlyComp] = useState(0);
  const [typeOfBuilding, setTypeOfBuilding] = useState("");
  const gatewaydata = useSelector((state) => state.gateWayData);
  const [acPercent, setAcPercent] = useState("");
  const [hotelStar, setHotelStar] = useState("");
  const dispatch = useDispatch();

  const showSnackBar = () => {
    setOpenNoti(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  useEffect(() => {
    if (gateway != null && gateway.hasOwnProperty("calculator_parameters")) {
      setAcCapacity(
        gateway.calculator_parameters.avg_conditioning_capacity || 0
      );
      setOpHours(gateway.calculator_parameters.operating_hours || 0);
      setArea(gateway.calculator_parameters.air_conditioned_area || 0);
      setMonthlyComp(gateway.calculator_parameters.monthyconsumption || 0);
      setTypeOfBuilding(gateway.calculator_parameters.type_of_building || "");
      setAcPercent(
        gateway.calculator_parameters.air_conditioned_percentage || ""
      );
      setHotelStar(gateway.calculator_parameters.hotelStar || "");
      setState(gateway.calculator_parameters.state || "");
      if (gateway.calculator_parameters.state) {
        setDistrictData(
          Object.keys(weatherData[gateway.calculator_parameters.state])
        );
      }
      setDistrict(gateway.calculator_parameters.district || "");
    }
  }, [gateway]);

  const submitHandler = async (e) => {
    e.preventDefault();

    let benchMark = 0;

    let climate = weatherData[state][district];

    if (typeOfBuilding === "office") {
      benchMark = epiData[typeOfBuilding][climate][acPercent];
    } else if (typeOfBuilding === "hotels") {
      benchMark = epiData[typeOfBuilding][climate][hotelStar];
    } else {
      benchMark = epiData[typeOfBuilding][climate];
    }
    console.log(climate);

    let acP = typeOfBuilding === "office" ? acPercent : "";
    let hotelS = typeOfBuilding === "hotels" ? hotelStar : "";
    try {
      const res = await api.put("/updatecalculatorparams", {
        monthyconsumption: monthlyComp,
        space_size: area,
        avg_conditioning_capacity: acCapacity,
        operating_hours: opHours,
        requirebenchmark: benchMark,
        air_conditioned_area: area,
        type_of_building: typeOfBuilding,
        air_conditioned_percentage: acP,
        hotelStar: hotelS,
        state: state,
        district: district,
        climate_zone: climate,
        gatewayID: props.gatewayID,
      });

      let data = [...gatewaydata];

      data = data.map((dat) => {
        if (dat.gatewayID === props.gatewayID) {
          dat.calculator_parameters = {
            ...dat.calculator_parameters,
            monthyconsumption: monthlyComp,
            space_size: area,
            avg_conditioning_capacity: acCapacity,
            operating_hours: opHours,
            requirebenchmark: benchMark,
            air_conditioned_area: area,
            type_of_building: typeOfBuilding,
            air_conditioned_percentage: acP,
            hotelStar: hotelS,
            state: state,
            district: district,
            climate_zone: climate,
            gatewayID: props.gatewayID,
          };
        }
        return dat;
      });

      dispatch(setGateWayData([...data]));
      props.handleClose();
      showSnackBar();
    } catch (e) {
      console.log(e);
    }
  };

  const changeHandler = (e) => {
    let val = e.target.value;
    let name = e.target.name;

    switch (name) {
      case "units":
        setMonthlyComp(val);
        break;
      case "typeOfBuilding":
        setTypeOfBuilding(val);
        break;
      case "area":
        setArea(val);
        break;
      case "operatingHours":
        setOpHours(val);
        break;
      case "acCapacity":
        setAcCapacity(val);
      default:
    }
  };

  return (
    <>
      <Notification
        open={openNoti}
        position={{ vertical: "top", horizontal: "center" }}
        handleClose={handleClose}
        errorMessage={"Data updated"}
        severity="success"
      ></Notification>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box
          sx={style}
          className=" mx-auto h-[80vh] md:w-[60%] w-[95%] overflow-y-auto"
        >
          <button
            onClick={props.handleClose}
            className="absolute right-5 top-3"
          >
            <Cancel sx={{ color: "red" }}></Cancel>
          </button>
          <Box className="w-[95%] mx-auto">
            <h1 className="text-xl text-[#545454]">
              Edit Gateway Details | Gateway ID : {props.gatewayID}
            </h1>
            <Divider></Divider>
            <div className="flex gap-3 justify-evenly flex-wrap">
              <Box className="mt-7  space-y-5">
                <form onSubmit={submitHandler}>
                  <div>
                    <h3
                      className={
                        textStyle + " after:content-['*'] after:text-[red]"
                      }
                    >
                      Average monthly electricity consumption (units as per
                      electricity bill)
                    </h3>
                    <TextField
                      sx={inputStyle}
                      variant="outlined"
                      required={true}
                      name="units"
                      value={monthlyComp}
                      onChange={changeHandler}
                      size="small"
                      className="w-[100%]"
                    ></TextField>
                  </div>
                  <div>
                    <h3
                      className={
                        textStyle + " after:content-['*'] after:text-[red]"
                      }
                    >
                      Size of the space (in square feet - sft.)
                    </h3>
                    <TextField
                      sx={inputStyle}
                      variant="outlined"
                      required={true}
                      name="area"
                      onChange={changeHandler}
                      value={area}
                      size="small"
                      fullWidth
                    ></TextField>
                  </div>
                  <div>
                    <h3 className={textStyle}>No. of working hours daily</h3>
                    <TextField
                      sx={inputStyle}
                      variant="outlined"
                      name="operatingHours"
                      onChange={changeHandler}
                      value={opHours}
                      size="small"
                      fullWidth
                    ></TextField>
                  </div>
                  <div>
                    <h3 className={textStyle}>Air conditioning capacity</h3>
                    <TextField
                      sx={inputStyle}
                      variant="outlined"
                      value={acCapacity}
                      name="acCapacity"
                      onChange={changeHandler}
                      size="small"
                      className="w-[100%]"
                    ></TextField>
                  </div>
                  <div className="">
                    <h3
                      className={
                        textStyle + " after:content-['*'] after:text-[red]"
                      }
                    >
                      Type of building
                    </h3>
                    <FormControl fullWidth size="small" required>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typeOfBuilding}
                        onChange={(e) => {
                          setTypeOfBuilding(e.target.value);
                        }}
                      >
                        <MenuItem value={"office"}>Office</MenuItem>
                        <MenuItem value={"shoppingMalls"}>Retail</MenuItem>
                        <MenuItem value={"hospitals"}>Healthcare</MenuItem>
                        <MenuItem value={"hotels"}>Hospitality</MenuItem>
                        <MenuItem value={"institutes"}>Institutes</MenuItem>
                        <MenuItem value={"bpos"}>BPO</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {typeOfBuilding === "office" && (
                    <div className="">
                      <h3
                        className={
                          textStyle + " after:content-['*'] after:text-[red]"
                        }
                      >
                        AC percentage
                      </h3>
                      <FormControl fullWidth size="small" required>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={acPercent}
                          onChange={(e) => {
                            setAcPercent(e.target.value);
                          }}
                        >
                          <MenuItem value={"moreThan50AC"}>Above 50%</MenuItem>
                          <MenuItem value={"lessThan50AC"}>Below 50%</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {typeOfBuilding === "hotels" && (
                    <div className="">
                      <h3
                        className={
                          textStyle + " after:content-['*'] after:text-[red]"
                        }
                      >
                        Hotel star
                      </h3>
                      <FormControl fullWidth size="small" required>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={hotelStar}
                          onChange={(e) => {
                            setHotelStar(e.target.value);
                          }}
                        >
                          <MenuItem value={"upto3Star"}>Upto 3 star</MenuItem>
                          <MenuItem value={"above3Star"}>Above 3 star</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  <div className="">
                    <h3
                      className={
                        textStyle + " after:content-['*'] after:text-[red]"
                      }
                    >
                      State
                    </h3>
                    <FormControl size="small" fullWidth required>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value);
                          setDistrictData(
                            Object.keys(weatherData[e.target.value])
                          );
                        }}
                      >
                        {stateData.map((state, i) => {
                          return (
                            <MenuItem value={state} key={i}>
                              {state}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className=" ">
                    <h3
                      className={
                        textStyle + " after:content-['*'] after:text-[red]"
                      }
                    >
                      District
                    </h3>
                    <FormControl size="small" fullWidth required>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={district}
                        onChange={(e) => {
                          setDistrict(e.target.value);
                        }}
                      >
                        {districtData.map((district, i) => {
                          return (
                            <MenuItem value={district} key={i}>
                              {district}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  {gateway !== null && (
                    <>
                      {/* {gateway.node1.mac !== "0:0:0:0:0:0" && (
                        <>
                          <h3 className={textStyle}>Node 1 Max Load (Kwh)</h3>
                          <TextField
                            sx={inputStyle}
                            variant="outlined"
                            value={node1Load}
                            type="number"
                            gateway
                            name="acCapacity"
                            onChange={(e) => {
                              setNode1Load(e.target.value);
                            }}
                            required={true}
                            size="small"
                          ></TextField>
                        </>
                      )} */}
                      {/* {gateway.node2.mac !== "0:0:0:0:0:0" && (
                        <>
                          <h3 className={textStyle}>Node 2 Max Load (Kwh)</h3>
                          <TextField
                            sx={inputStyle}
                            variant="outlined"
                            value={node2Load}
                            type="number"
                            name="acCapacity"
                            onChange={(e) => {
                              setNode2Load(e.target.value);
                            }}
                            required={true}
                            size="small"
                          ></TextField>
                        </>
                      )}
                      {gateway.node3.mac !== "0:0:0:0:0:0" && (
                        <>
                          <h3 className={textStyle}>Node 3 Max Load (Kwh)</h3>
                          <TextField
                            sx={inputStyle}
                            variant="outlined"
                            value={node3Load}
                            type="number"
                            name="acCapacity"
                            onChange={(e) => {
                              setNode3Load(e.target.value);
                            }}
                            required={true}
                            size="small"
                          ></TextField>
                        </>
                      )}
                      {gateway.node4.mac !== "0:0:0:0:0:0" && (
                        <>
                          <h3 className={textStyle}>Node 4 Max Load (Kwh)</h3>
                          <TextField
                            sx={inputStyle}
                            variant="outlined"
                            value={node4Load}
                            type="number"
                            name="acCapacity"
                            onChange={(e) => {
                              setNode4Load(e.target.value);
                            }}
                            required={true}
                            size="small"
                          ></TextField>
                        </>
                      )} */}
                    </>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      display: "block",
                      backgroundColor: "#F97316",
                      mt: "10px",
                      ":hover": { backgroundColor: "#F97316" },
                    }}
                  >
                    Update
                  </Button>
                </form>
              </Box>
              {/* <div className="flex justify-center items-center">
                <img
                  src={require("../asset/indiaClimateZone.png")}
                  alt="india map"
                  className="md:w-[400px] lg:w-[500px]"
                ></img>
              </div> */}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GateWayDetailsModal;
