import React from "react";
import { Snackbar, Alert } from "@mui/material";
const Notification = (props) => {
  return (
    <Snackbar
      anchorOrigin={
        props.position
          ? props.position
          : { vertical: "top", horizontal: "right" }
      }
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleClose}
    >
      <Alert
        onClose={props.handleClose}
        severity={props.severity}
        sx={{ width: "100%" }}
      >
        {props.errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
