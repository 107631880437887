import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { convertTimeStampToDate } from "../../helperFunc/convertDate";
import { convert } from "../../helperFunc/graphDate";
import { Checkbox } from "@mui/material";
import GraphLegends from "./GraphLegends";
import DownloadMenu from "./DownloadMenu";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CurrentHermonicsGraph = (props) => {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [dataC, setDataC] = useState([]);
  const [date, setDate] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);

  useEffect(() => {
    const dataA = [];
    const dataB = [];
    const dataC = [];
    const csvDat = [];
    const dates = [];

    props.data.forEach((data) => {
      let foo = data.Timestamp.toString();
      let date = convertTimeStampToDate(foo);

      dates.push(date);
      dataA.push({
        x: convert(date, props.showTime),
        y: parseFloat(data[props.nodeSelect]?.THDIA) || 0,
      });
      dataB.push({
        x: convert(date, props.showTime),
        y: parseFloat(data[props.nodeSelect]?.THDIB) || 0,
      });
      dataC.push({
        x: convert(date, props.showTime),
        y: parseFloat(data[props.nodeSelect]?.THDIC) || 0,
      });

      csvDat.push(
        [
          convert(date, props.showTime),
          parseFloat(data[props.nodeSelect]?.THDIA) || 0,
          parseFloat(data[props.nodeSelect]?.THDIB) || 0,
          parseFloat(data[props.nodeSelect]?.THDIC) || 0,
        ].join(",")
      );
    });
    dataA.reverse();
    dataB.reverse();
    dataC.reverse();
    setCsvData(csvDat);
    setDataA(dataA);
    setDataB(dataB);
    setDataC(dataC);

    setDate(dates);
  }, [props.data, props.nodeSelect]);

  const chartDetails = {
    options: {
      chart: {
        id: "CurrentHermonicsGraph",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      xaxis: {
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          show: true,
          rotate: 0,
        },
        title: {
          text: "Time",
        },
      },
      yaxis: {
        title: {
          text: "Percentage (%)",
        },
      },
      stroke: {
        width: "2",
        curve: "smooth",
      },
      legend: { show: false },
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Phase A",
        data: dataA,
        color: "#EA5C54",
      },
      {
        name: "Phase B",
        data: dataB,
        color: "#EFC65E",
      },
      {
        name: "Phase C",
        data: dataC,
        color: "#298F9D",
      },
    ],
  };
  if (!display) {
    return <></>;
  }

  const toggle = (e) => {
    ApexCharts.exec("CurrentHermonicsGraph", "toggleSeries", e.target.value);
  };

  const downloadImg = () => {
    ApexCharts.exec("CurrentHermonicsGraph", "dataURI", { scale: 3 }).then(
      ({ imgURI }) => {
        const anchor = document.createElement("a");
        anchor.href = imgURI;
        anchor.download = "CurrentHermonicsGraph.png";
        anchor.click();
      }
    );
  };

  const CsvDownload = () => {
    const header = "Date,Phase A,Phase B,Phase C";
    let data = [header, ...csvData];

    let csv = data.join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", "CurrentHermonicsGraph.csv");
    a.click();
  };

  return (
    <div className="relative">
      <Chart
        options={chartDetails.options}
        series={chartDetails.series}
        type="line"
        height={props.graphHeight}
      />
      <GraphLegends toggle={toggle}></GraphLegends>
      <DownloadMenu downloadImg={downloadImg} downloadCsv={CsvDownload} />
    </div>
  );
};

export default CurrentHermonicsGraph;
