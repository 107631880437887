import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { convertTimeStampToDate } from "../../helperFunc/convertDate";
import { convert } from "../../helperFunc/graphDate";
import ApexCharts from "apexcharts";
import DownloadMenu from "./DownloadMenu";

const PFgraph = (props) => {
  const [dataA, setDataA] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);

  useEffect(() => {
    let dat = [];
    const csvDat = [];
    props.data.forEach((data) => {
      let foo = data.Timestamp.toString();
      let date = convertTimeStampToDate(foo);

      // data.push(date);
      dat.push({
        x: convert(date, props.showTime),
        y: parseFloat(data[props.nodeSelect]?.Mean_PF) || 0,
      });
      csvDat.push(
        [
          convert(date, props.showTime),
          parseFloat(data[props.nodeSelect]?.Mean_PF) || 0,
        ].join(",")
      );
    });
    dat.reverse();
    setCsvData(csvDat);
    setDataA(dat);
  }, [props.data, props.nodeSelect]);

  const chartDetails = {
    options: {
      chart: {
        id: "PF graph",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      xaxis: {
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          show: true,
          rotate: 0,
        },
        title: {
          text: "Time",
        },
      },
      yaxis: {
        title: {
          text: "Power factor",
        },
      },
      stroke: {
        width: "2",
        curve: "smooth",
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "PF",
        data: dataA,
        color: "#298F9D",
      },
    ],
  };
  if (!display) {
    return <></>;
  }

  const downloadImg = () => {
    ApexCharts.exec("PF graph", "dataURI", { scale: 3 }).then(({ imgURI }) => {
      const anchor = document.createElement("a");
      anchor.href = imgURI;
      anchor.download = "PF.png";
      anchor.click();
    });
  };

  const CsvDownload = () => {
    const header = "Date,Power factor";
    let data = [header, ...csvData];

    let csv = data.join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", "PFdata.csv");
    a.click();
  };

  return (
    <div className="relative">
      <Chart
        options={chartDetails.options}
        series={chartDetails.series}
        type="line"
        height={props.graphHeight}
      />
      <DownloadMenu downloadImg={downloadImg} downloadCsv={CsvDownload} />
    </div>
  );
};

export default PFgraph;
