const epiBenchmarks = {
  office: {
    warmHumid: {
      lessThan50AC: 101,
      moreThan50AC: 182,
    },
    composite: {
      lessThan50AC: 86,
      moreThan50AC: 179,
    },
    hotDry: {
      lessThan50AC: 90,
      moreThan50AC: 173,
    },
    moderate: {
      lessThan50AC: 94,
      moreThan50AC: 179,
    },
    cold: {
      lessThan50AC: 65,
      moreThan50AC: 96,
    },
  },
  shoppingMalls: {
    warmHumid: 428,
    composite: 327,
    hotDry: 273,
    moderate: 257,
    cold: 96,
  },
  hospitals: {
    warmHumid: 275,
    composite: 264,
    hotDry: 261,
    moderate: 247,
    cold: 96,
  },
  hotels: {
    warmHumid: {
      upto3Star: 215,
      above3Star: 333,
    },
    composite: {
      upto3Star: 201,
      above3Star: 290,
    },
    hotDry: {
      upto3Star: 167,
      above3Star: 250,
    },
    moderate: {
      upto3Star: 107,
      above3Star: 313,
    },
    cold: {
      upto3Star: 96,
      above3Star: 96,
    },
  },
  institutes: {
    warmHumid: 150,
    composite: 117,
    hotDry: 106,
    moderate: 129,
    cold: 96,
  },
  bpos: {
    warmHumid: 452,
    hotDry: 437,
    composite: 437,
    moderate: 433,
    cold: 96,
  },
};

export default epiBenchmarks;
