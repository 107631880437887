import { combineReducers } from "redux";
import {
  loginState,
  clientData,
  gateWayData,
  mailSent,
  invoice,
} from "./reducers/index";
const rootReducer = combineReducers({
  loginState,
  clientData,
  gateWayData,
  mailSent,
  invoice,
});

export default rootReducer;
