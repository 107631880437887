import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Box, IconButton, Paper } from "@mui/material";
import DeviceInfo from "./DeviceInfo";
import LiveData from "./LiveData";
import Analytics from "./Analytics";
import Profile from "./Profile";
import { ZoomOutMap, ZoomInMap, Menu as MenuIcon } from "@mui/icons-material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

import Reports from "./Reports";
import createInstance from "../api";
import { useDispatch, useSelector } from "react-redux";
import Bill from "./Bill";
import VerifyPayments from "./VerifyPayments";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
// import LiveData from "./LiveData";

const Dashboard = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  let api = createInstance();

  const clientData = useSelector((state) => {
    return state.clientData;
  });
  const invoice = useSelector((state) => state.invoice);
  let showDue = true;

  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const res = await api.post("/paymentinfo", {
        custid: clientData.OrganizationID,
      });

      dispatch({ type: "SET_INVOICE", payload: res.data });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    });

    getData();
  }, []);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handelScreen = () => {
    try {
      if (document.fullscreenElement == null) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="font-sans">
      <BrowserRouter>
        <Box sx={{ display: "flex" }}>
          <Navbar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - 285px)` },
              paddingBottom: "",
              backgroundColor: "#EDEDED",
              padding: 0,
              position: "relative",
              overflow: "hidden",
            }}
            className="min-h-screen flex flex-col"
          >
            <Box
              className="absolute top-2 right-5 z-50"
              style={{ cursor: "pointer" }}
              sx={{ display: { xs: "none", md: "block", lg: "block" } }}
              onClick={handelScreen}
            >
              {isFullscreen ? <ZoomInMapIcon /> : <ZoomOutMap />}
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: "none" },
                color: "#818181",
                position: "absolute",
                left: "10px",
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <div className="w-[100%] bg-[#EFC65E] absolute z-50 bottom-0 p-3 text-center rounded-lg">
              Bill due
            </div> */}
            <Routes>
              <Route path="/" element={<DeviceInfo />}></Route>
              <Route path="/device/:gateWayId" element={<LiveData />}></Route>
              <Route path="/analytics" element={<Analytics />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/reports" element={<Reports />}></Route>
              <Route path="/bill" element={<Bill />}></Route>
              <Route path="/refund-policy" element={<RefundPolicy />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route
                path="/payment/:transId"
                element={<VerifyPayments />}
              ></Route>
            </Routes>
            <Paper className="flex justify-center items-center  p-2   mt-auto w-[100%] left-0 flex-col bg-white shadow-lg text-sm rounded-lg">
              <span className="text-[#545454] font-medium">
                Zodhya {new Date().getFullYear()}
              </span>
              <div className="flex gap-2 ">
                <Link
                  to="/refund-policy"
                  className="underline text-blue-600 cursor-pointer"
                >
                  Refund policy
                </Link>
                <Link
                  to="/privacy-policy"
                  className="underline text-blue-600 cursor-pointer"
                >
                  Privacy policy
                </Link>
                <a
                  href="https://www.zodhyatech.com/contact.html"
                  target="_black"
                  className="underline text-blue-600 cursor-pointer"
                >
                  Contact us
                </a>
              </div>
            </Paper>
          </Box>
        </Box>
      </BrowserRouter>
    </div>
  );
};

export default Dashboard;
