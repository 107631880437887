import React, { useContext, useState } from "react";
import { AccountContext } from "./Account";
import { Box, Button, Divider, TextField } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import PasswordChecklist from "react-password-checklist";
import PasswordStrengthBar from "react-password-strength-bar";
import Notification from "./Notification";

import CustomBtn from "./CustomBtn";
const ChangePassword = (props) => {
  const { handleClose, showSnackBar, setErrorMessage, setSeverity } = props;

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const { getSession } = useContext(AccountContext);

  const submitHandler = (e) => {
    e.preventDefault();
    if (newPass !== confirmNewPass) {
      alert("confirm password is not same");
      return;
    }
    getSession().then(({ user }) => {
      user.changePassword(currentPass, newPass, (err, result) => {
        if (err) {
          setErrorMessage(err.message);
          setSeverity("error");
          showSnackBar();
        } else {
          setSeverity("success");
          setErrorMessage("Password Changed Successfully");
          showSnackBar();
          handleClose();
        }
      });
    });
  };

  const changeHandler = (e) => {
    let name = e.target.name;

    switch (name) {
      case "currPass":
        setCurrentPass(e.target.value);
        break;
      case "newPass":
        setNewPass(e.target.value);
        break;
      case "confirmNewPass":
        setConfirmNewPass(e.target.value);
        break;
      default:
    }
  };

  return (
    <>
      <form
        onSubmit={submitHandler}
        className="flex justify-start items-start  flex-col gap-3 w-fit"
      >
        <h1 className="text-[#545454] underline underline-offset-2">
          Change Password
        </h1>
        <TextField
          label="Current Password"
          name="currPass"
          variant="outlined"
          size="small"
          type="text"
          required={true}
          value={currentPass}
          onChange={changeHandler}
        ></TextField>
        <TextField
          label="New Password"
          variant="outlined"
          size="small"
          type="text"
          name="newPass"
          required={true}
          value={newPass}
          onChange={changeHandler}
        ></TextField>

        <TextField
          label="Confirm Password"
          variant="outlined"
          size="small"
          type="text"
          name="confirmNewPass"
          required={true}
          value={confirmNewPass}
          onChange={changeHandler}
        ></TextField>
        {newPass !== "" && (
          <>
            <PasswordStrengthBar
              password={newPass}
              minLength={8}
              className="w-[80%]"
            />
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            width: "100px",
            backgroundColor: "#F97316",
            ":hover": { backgroundColor: "#F97316" },
          }}
          type="submit"
        >
          Submit
        </Button>

        {/* <PasswordChecklist
              rules={[
                "capital",
                "match",
                "specialChar",
                "minLength",
                "number",
                "lowercase",
              ]}
              minLength={8}
              value={newPass}
              valueAgain={confirmNewPass}
            /> */}
      </form>
    </>
  );
};

export default ChangePassword;
