import React from "react";
import LoadingGif from "../asset/Loading.gif";

const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center flex-col">
      <img src={LoadingGif} alt="loadingGif" className="h-20"></img>
      <p>Loading...</p>
    </div>
  );
};

export default Loader;
