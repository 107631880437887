import { Button, styled } from "@mui/material";

const Btn = styled(Button)(({ theme }) => ({
  color: "white",
  // fontSize: "1.3rem",
  borderRadius: "10px",
  minWidth: "90px",
  background:
    "linear-gradient(90deg, rgba(234,92,84,1) 0%, rgba(239,132,58,1) 42%, rgba(239,198,94,1) 100%)",
  filter:
    'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea5c54",endColorstr="#efc65e",GradientType=1)',
}));

const CustomBtn = (props) => {
  return <Btn {...props}></Btn>;
};

export default CustomBtn;
